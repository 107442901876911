import { useState, useEffect } from 'react';
import { Input, Button, Select } from 'shared/theme/elements';
import { Table } from 'shared/theme/elements/Table';
import { Card, Col, Row, Avatar, Form } from 'antd';
import { CardStyled, CardWidth, SmallInputStyle, CardInputStyle, TableContainer } from '../PostsList/PostsList.style';
import { ACCOUNT_STATUS, VERIFICATION_STATUS } from 'shared/constants/DATA';
import { ITableColumn, ITableData } from 'shared/interfaces/utils/ITable';
import { ColumnSettingComponent } from 'pages/ColumnSetting';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckCircleFilled } from '@ant-design/icons';
import { toTitleCase } from 'shared/utils/toTitleCase';
import { getIntial } from 'shared/utils/getInitial';
import { FormStyled } from './InfluencersList.style';
import ClickAwayListener from 'react-click-away-listener';
import { FormInstance } from 'antd/es/form/Form';
import { useQueryClient } from 'react-query';

type Props = {
  influencerColumns: ITableColumn[];
  data: any;
  handlePageChange: (page: number) => void;
  page: number;
  handlePageSize: (num: number) => void;
  pageSize: number;
  handleCountryChange: (value: any) => void;
  // handleAccountStatusChange: (value: any) => void;
  // handleVerificationStatusChange: (value: any) => void;
  selectedCountry: string[];
  accountStatus: string[];
  verificationStatus: string;
  checkColumns: CheckboxValueType[];
  handleColumns: (checkedValues: CheckboxValueType[]) => void;
  setCheckedColumns: (checkedValues: CheckboxValueType[]) => void;
  isLoading: boolean;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  handleClear: (form: FormInstance<any>) => void;
  handleApplyFilter: (
    interests: string,
    range: string,
    country: string,
    status: string,
    verification: string,
    username: string,
  ) => void;
};

type TablePaginationPosition = 'topRight' | 'bottomRight';

const InfluencersListView: React.FC<Props> = ({
  accountStatus,
  verificationStatus,
  influencerColumns,
  data,
  handlePageChange,
  handlePageSize,
  checkColumns,
  handleColumns,
  setCheckedColumns,
  isLoading,
  disabled,
  setDisabled,
  handleClear,
  handleApplyFilter,
}: Props) => {
  const tableData: ITableData[] = [];
  const [brokenImages, setBrokenImages] = useState<string[]>([]);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const isFieldsTouch = form.isFieldsTouched();
  const fieldsValue = form.getFieldsValue(true);

  useEffect(() => {
    queryClient.invalidateQueries('influencers_list');
  }, [queryClient]);

  useEffect(() => {
    //sconsole.log(fieldsValue);
    if (isFieldsTouch) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isFieldsTouch, setDisabled, fieldsValue]);

  data?.data.map((item) => {
    const onError = () => {
      setBrokenImages((current) => [...current, item.social_poster_profile_img]);
      return false;
    };

    tableData.push({
      key: item._id,
      avatar: (
        <>
          {!brokenImages.includes(item.social_poster_profile_img) && (
            <Avatar src={item.social_poster_profile_img} onError={onError} />
          )}
          {brokenImages.includes(item.social_poster_profile_img) && (
            <Avatar size="large">{getIntial(item.social_poster_username)}</Avatar>
          )}
        </>
      ),
      influencer_name: (
        <span>
          {item.social_poster_name ? item.social_poster_name : item.social_poster_identifier}
          {item.social_poster_verified === 'True' ? (
            <CheckCircleFilled style={{ margin: '10px', color: '#133774' }} />
          ) : null}
        </span>
      ),
      account_status: toTitleCase(item.social_poster_account_status),
      username: `@${item.social_poster_username}`,
      interests: '',
      biography: '',
      influencer_url: (
        <a href={`${item.social_poster_profile_url}`} target="_blank">
          {item.social_poster_profile_url.replace('https://', '')}
        </a>
      ),
      country: item.social_poster_country ? item.social_poster_country : '-',
      following: item.social_poster_following_count.toLocaleString(),
      followers: item.social_poster_follower_count.toLocaleString(),
      tweets: item.social_poster_content_count.toLocaleString(),
    });
  });

  const [showFollowers, setShowFollowers] = useState(false);
  const [top] = useState<TablePaginationPosition>('topRight');
  const [bottom] = useState<TablePaginationPosition>('bottomRight');
  const FollowersCard = () => (
    <CardStyled>
      <Card style={CardWidth}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item name="followersMin">
            <Input style={{ ...CardInputStyle, marginRight: '5px' }} placeholder="0" size="middle" maxLength={11} />
          </Form.Item>
          <Form.Item name="followersMax">
            <Input style={CardInputStyle} placeholder="99999999999" size="middle" maxLength={11} />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            style={{ float: 'right' }}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              form.setFieldsValue({
                followers: `${form.getFieldValue('followersMin')}-${form.getFieldValue('followersMax')}`,
                followersMin: '',
                followersMax: '',
              });
              setShowFollowers(false);
            }}
          >
            Set
          </Button>
        </Form.Item>
      </Card>
    </CardStyled>
  );

  return (
    <div>
      <FormStyled form={form} layout="vertical" initialValues={{ status: 'ALL', verification: 'all' }}>
        <Row align="bottom">
          <ColumnSettingComponent
            from={'Influencers'}
            checkColumns={checkColumns}
            setCheckedColumns={setCheckedColumns}
            handleColumns={handleColumns}
          />
          <label>
            <span>Platform</span>
            <Form.Item name="plaform">
              <Select
                style={{ ...SmallInputStyle, marginBottom: '20px' }}
                size="middle"
                options={[{ value: 'twitter', label: 'Twitter' }]}
                defaultValue="twitter"
              ></Select>
            </Form.Item>
          </label>
        </Row>
        <Row align="middle">
          <Col span={20}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <label>
                <span>Influencer Name</span>
                <Form.Item name="influencer_name">
                  <Input
                    style={{ ...SmallInputStyle, width: '325px' }}
                    placeholder={`"Andrea" OR "COMELEC"`}
                    size="middle"
                  />
                </Form.Item>
              </label>
              <label>
                <span>Followers</span>
                <ClickAwayListener onClickAway={() => setShowFollowers(false)}>
                  <div>
                    <Form.Item name="followers">
                      <Input
                        style={SmallInputStyle}
                        placeholder="Set Range"
                        size="middle"
                        onClick={() => setShowFollowers(!showFollowers)}
                      />
                    </Form.Item>

                    {showFollowers ? <FollowersCard /> : null}
                  </div>
                </ClickAwayListener>
              </label>
              <label>
                <span>Interests</span>
                <Form.Item name="interests">
                  <Select
                    style={{ ...SmallInputStyle, marginBottom: '20px' }}
                    size="middle"
                    options={[{ value: 'all', label: 'All' }]}
                    defaultValue="all"
                  ></Select>
                </Form.Item>
              </label>
              <label>
                <span>Biography</span>
                <Form.Item name="biography">
                  <Select
                    style={{ ...SmallInputStyle, marginBottom: '20px' }}
                    size="middle"
                    options={[{ value: 'all', label: 'All' }]}
                    defaultValue="all"
                  ></Select>
                </Form.Item>
              </label>
              {/* <label>
                <span>Country</span>
                <Form.Item name="country">
                  <Select
                    style={{ ...SmallInputStyle, width: '125px' }}
                    isRequired
                    items={COUNTRIES}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    size="middle"
                  />
                </Form.Item>
              </label> */}
              <label>
                <span>Account Status</span>
                <Form.Item name="status">
                  <Select
                    style={{ ...SmallInputStyle, width: '125px' }}
                    isRequired
                    items={ACCOUNT_STATUS}
                    value={accountStatus}
                    //onChange={handleAccountStatusChange}
                    size="middle"
                  />
                </Form.Item>
              </label>
              <label>
                <span>Verification</span>
                <Form.Item name="verification">
                  <Select
                    style={{ ...SmallInputStyle, width: '125px' }}
                    isRequired
                    items={VERIFICATION_STATUS}
                    value={verificationStatus}
                    //onChange={handleVerificationStatusChange}
                    size="middle"
                  />
                </Form.Item>
              </label>
            </div>
          </Col>
          <Col span={4}>
            <div>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="primary"
                    size="small"
                    style={{ margin: '9px 5px 0px 5px', height: '32px' }}
                    onClick={() => {
                      const interests = form.getFieldValue('interests');
                      const range = form.getFieldValue('followers')
                        ? form.getFieldValue('followers').replace('-', ',').split(',').map(Number)
                        : [0, 99999999999];
                      const country = form.getFieldValue('country');
                      const status = form.getFieldValue('status') ? form.getFieldValue('status') : 'ALL';
                      const verification = form.getFieldValue('verification');
                      const influencerName = form.getFieldValue('influencer_name');

                      handleApplyFilter(interests, range, country, status, verification, influencerName);
                    }}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    size="small"
                    style={{ margin: '9px 0px 0px 0px', height: '32px' }}
                    onClick={() => handleClear(form)}
                    disabled={disabled}
                  >
                    Clear
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </FormStyled>

      <Row>
        <TableContainer>
          <Table
            showSorterTooltip={false}
            loading={isLoading}
            data={tableData}
            columns={influencerColumns}
            pagination={{
              current: data?.meta?.pagination?.current_page,
              total: data?.meta?.pagination?.total_pages || 0,
              onChange: (newPage: number, currentPageSize: number) => {
                handlePageChange(newPage);
                handlePageSize(currentPageSize);
              },
              showTotal: (total, range) =>
                `${range[0].toLocaleString()}-${range[1].toLocaleString()} of ${total.toLocaleString()} results`,
              position: [top, bottom],
            }}
          />
        </TableContainer>
      </Row>
    </div>
  );
};

export default InfluencersListView;
