export const ENDPOINTS = {
  ARTICLES: '/temportia/tweets',
  INFLUENCERS: '/influencer',
  COUNTRIES: '/country',
  TPM: '/temportia//historical_data/average',
  HISTORICAL_DATA: '/temportia//historical_data',
  BANDWIDTH: '/temportia/summary/network_bandwith',
  TWEET_COUNT: '/temportia/summary/tweet_count',
  STATUS: '/temportia/summary/statuses',
  INFLUENCER_SCRAPE: 'temportia/summary/influencers_scaped',
  INFLUENCER_PER_DAY: 'temportia/summary/influencers_per_day',
  INFLUENCER_TIMELINE: 'temportia/summary/influencers_scaped_timeline',
  CUSTOMTOPICS: '/custom_topics',
  KEYWORDS: '/keyword',
};
