import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const StringPreviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const StringPreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 12px;

  background-color: #fafafa;
  border-radius: 2px;
`;

export const StringPreviewParagraph = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const StringPreviewBlueSpan = styled.span`
  color: #3771c8;
`;

export const StringPreviewOtherKeywordsSpan = styled.span`
  color: #605aa6;
`;

export const StringPreviewGreenSpan = styled.span`
  color: #00cd6e;
`;

export const StringPreviewOtherAdditionalKeywordsSpan = styled.span`
  color: orange;
`;

export const StringPreviewRedSpan = styled.span`
  color: #f44336;
`;

export const StringPreviewOtherExcludedKeywordsSpan = styled.span`
  color: black;
`;

// export const BigKeywordContainer = styled.div`
//   padding: 12px;
//   gap: 10px;
// `;

export const BigKeywordButton = styled(AntButton)`
  width: 100%;
  height: 160px;
  /* Gray/gray-2 */
  background: #fafafa;
  /* Gray/gray-4 */
  border: 1px dashed #f0f0f0;
  border-radius: 2px 2px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
`;

export const BigAdditionalKeywordButton = styled(AntButton)`
  width: 100%;
  height: 160px;

  /* Gray/gray-2 */
  background: #fafafa;
  /* Gray/gray-4 */
  border: 1px dashed #f0f0f0;
  border-radius: 2px 2px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
`;

export const BigExcludedKeywordButton = styled(AntButton)`
  width: 100%;
  height: 160px;

  /* Gray/gray-2 */
  background: #fafafa;
  /* Gray/gray-4 */
  border: 1px dashed #f0f0f0;
  border-radius: 2px 2px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
`;

export const AdditionalKeywordContainer = styled.div`
  margin-top: 0.75rem;
`;
