import styled from 'styled-components';
import { Card, Form, Tag } from 'antd';
import { Radio } from 'shared/theme/elements';

export const FormStyled = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-label {
    padding: 10px 0px 0px 0px;
  }
`;

// export const SelectStyled = styled(Select)`
//   .ant-select-selection-item {
//     background-color: rgb(96, 90, 166);
//     color: #ffffff;
//     border-radius: 2px;
//   }

//   .ant-select-selection-item-content {
//     padding-right: 5px;
//   }

//   .ant-select-selection-item-remove {
//     color: rgba(255, 255, 255, 0.45);
//   }
// `;

export const RadioStyled = styled(Radio)`
  margin: '5px 3px 0px 0px';
`;

export const CardStyled = styled(Card)`
  height: 200px;
`;

export const StyledTag = styled(Tag)`
  padding: 8px 14px;
  font-size: 14px;
  .anticon {
    margin-left: 12px;
  }
`;

export const TopCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CountSpan = styled.span`
  font-size: 2em;
  margin-right: 5px;
`;

export const PercentageDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const ColumnChartContainer = styled(Card)`
  width: 100%;
  margin-top: 20px;
  height: 455px;
`;
