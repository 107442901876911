import sign from 'jwt-encode';
import { CONFIG } from 'shared/config/config';
// import { IUser } from 'shared/interfaces/IAuth';
// import { ls } from './ls';

export const transformPayload = <T>(payload?: T): string => {
  const data = {
    ...payload,
    access_token: 'niLCQC9hWP4ef6KLyrXJQbVLEeJtE4vtLGcHi75R1S5dHw0efjjbOhlXRmQJJfTL',
  };
  const jwt = sign(data, CONFIG.ACCESS_SECRET);
  return jwt;
};
