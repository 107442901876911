import { Tag, Typography } from 'antd';
import styled from 'styled-components';

type CSSTypographyProps = {
  $variant?: 'primary' | 'dark' | 'grey';
  $color?: string;
  $fontSize?: string;
  $fontWeight?: 'light' | 'normal' | 'bold';
};

export const CustomTopicViewContainer = styled.div`
  width: 100%;
`;

export const CustomTopicContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const CustomTopicLinkBlue = styled.span`
  cursor: pointer;
  color: #1890ff;
`;

export const CustomTopicLinkPurple = styled.span`
  cursor: pointer;
  color: #605aa6;
`;

export const CustomTopicLinkOrange = styled.span`
  cursor: pointer;
  color: #fd7e14;
`;

export const CustomTopicLinkBlack = styled.span`
  cursor: pointer;
  color: #222;
`;

export const CustomTopicLinkYellow = styled.span`
  cursor: pointer;
  color: yellow;
`;

export const CustomTopicLinkGreen = styled.span`
  cursor: pointer;
  color: #00cd6e;
`;

export const CustomTopicLinkRed = styled.span`
  cursor: pointer;
  color: #f44336;
`;

export const StyledTag = styled(Tag)`
  padding: 8px 14px;
  font-size: 14px;
  .anticon {
    margin-left: 12px;
  }
`;

// FOR MODAL STYLE

export const TextPreview = styled(Typography.Paragraph)<CSSTypographyProps>`
  color: ${({ $color, $variant, theme }) => {
    if (!$color && !$variant) return theme.darkColor;
    if ($variant) {
      if ($variant === 'primary') {
        return theme.primaryColor;
      } else if ($variant === 'dark') {
        return theme.darkColor;
      } else if ($variant === 'grey') {
        return theme.greyColor;
      }
    }
    return $color;
  }} !important;
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '16px')} !important;
  font-weight: ${({ $fontWeight }) =>
    $fontWeight ? ($fontWeight === 'light' ? '400' : $fontWeight) : '500'} !important;
`;
