import { ICustomTopicKeywordListProps } from 'shared/interfaces/ICustomTopicKeywordListProps';

const mappedString = (str: string[]) =>
  str.map((s) => {
    if (s.includes(' ')) {
      return `"${s.trim()}"`;
    } else {
      return `${s.trim()}`;
    }
  });

const stringMapperNot = (str: string[]) =>
  str.map((s) => {
    if (s.includes(' ')) {
      return `"-${s.trim()}"`;
    } else {
      return `-${s.trim()}`;
    }
  });

export const customTopicKeywordPreviewParser = ({
  keywords,
  additionalKeywords,
  excludedKeywords,
  //
  firstAdditionalKeywords,
  secondAdditionalKeywords,
  lastAdditionalKeywords,
}: ICustomTopicKeywordListProps) => {
  //
  let parsedKeyword = '';
  let parsedAdditionalKeywords = '';
  let parsedExcludedKeywords = '';
  //
  let parsedFirstAdditionalKeywords = '';
  let parsedSecondAdditionalKeywords = '';
  let parsedLastAdditionalKeywords = '';

  if (keywords.length > 0) {
    const mappedKeywords = mappedString(keywords);
    parsedKeyword = `(${mappedKeywords.join(' OR ')})`;
  }

  if (additionalKeywords.length > 0) {
    const mappedAdditionalKeywords = mappedString(additionalKeywords);
    parsedAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
  }

  //
  if (firstAdditionalKeywords.length > 0) {
    const mappedAdditionalKeywords = mappedString(firstAdditionalKeywords);
    parsedFirstAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
  }
  //
  if (secondAdditionalKeywords.length > 0) {
    const mappedAdditionalKeywords = mappedString(secondAdditionalKeywords);
    parsedSecondAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
  }
  //
  if (lastAdditionalKeywords.length > 0) {
    const mappedAdditionalKeywords = mappedString(lastAdditionalKeywords);
    parsedLastAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
  }

  if (excludedKeywords.length > 0) {
    const mappedExcludedKeywords = stringMapperNot(excludedKeywords);
    parsedExcludedKeywords = `(${mappedExcludedKeywords.join(' OR ')})`;
  }

  return [
    parsedKeyword,
    parsedAdditionalKeywords,
    parsedExcludedKeywords,
    parsedFirstAdditionalKeywords,
    parsedSecondAdditionalKeywords,
    parsedLastAdditionalKeywords,
  ];
};

// import { IKeywordListProps } from 'shared/interfaces/utils/IKeywordListProps';

// const mappedString = (string: string[]) => string.map((str) => `"${str}"`);

// export const customTopicKeywordPreviewParser = ({
//   keywords,
//   additionalKeywords,
//   excludedKeywords,
//   //
//   firstAdditionalKeywords,
//   secondAdditionalKeywords,
//   lastAdditionalKeywords,
// }: IKeywordListProps) => {
//   //
//   let parsedKeyword = '';
//   let parsedAdditionalKeywords = '';
//   let parsedExcludedKeywords = '';
//   //
//   let parsedFirstAdditionalKeywords = '';
//   let parsedSecondAdditionalKeywords = '';
//   let parsedLastAdditionalKeywords = '';

//   if (keywords.length > 0) {
//     const mappedKeywords = mappedString(keywords);
//     parsedKeyword = `(${mappedKeywords.join(' OR ')})`;
//   }

//   if (additionalKeywords.length > 0) {
//     const mappedAdditionalKeywords = mappedString(additionalKeywords);
//     parsedAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
//   }

//   //
//   if (firstAdditionalKeywords.length > 0) {
//     const mappedAdditionalKeywords = mappedString(firstAdditionalKeywords);
//     parsedFirstAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
//   }
//   //
//   if (secondAdditionalKeywords.length > 0) {
//     const mappedAdditionalKeywords = mappedString(secondAdditionalKeywords);
//     parsedSecondAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
//   }
//   //
//   if (lastAdditionalKeywords.length > 0) {
//     const mappedAdditionalKeywords = mappedString(lastAdditionalKeywords);
//     parsedLastAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
//   }

//   if (excludedKeywords.length > 0) {
//     const mappedExcludedKeywords = mappedString(excludedKeywords);
//     parsedExcludedKeywords = `AND NOT (${mappedExcludedKeywords.join(' OR ')})`;
//   }

//   return [
//     parsedKeyword,
//     parsedAdditionalKeywords,
//     parsedExcludedKeywords,
//     parsedFirstAdditionalKeywords,
//     parsedSecondAdditionalKeywords,
//     parsedLastAdditionalKeywords,
//   ];
// };
