import { useQuery } from 'react-query';
import { InfluencerPerDayDao } from 'shared/dao/InfluencerPerDayDao';
import { InfluencerPerDayRequestPayload, InfluencerPerDayResponsePayload } from 'shared/interfaces/IInfluencerPerDay';

export const useInfluencerPerDayService = () => {
  const { getInfluencerPerDayData: getInfluencerPerDayDataDao } = InfluencerPerDayDao();
  const getInfluencerPerDay = (payload?: InfluencerPerDayRequestPayload) => {
    const { data } = useQuery<InfluencerPerDayResponsePayload>(['influencer_per_day', payload], () =>
      getInfluencerPerDayDataDao(payload),
    );
    return {
      data,
    };
  };
  return {
    getInfluencerPerDay,
  };
};
