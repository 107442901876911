import { Column, ColumnConfig } from '@ant-design/plots';
import React from 'react';
import { InfluencerTimelineResponsePayload } from 'shared/interfaces/IInfluencerTimeline';

type Props = {
  influencerTimelineData: InfluencerTimelineResponsePayload | undefined;
  width: number;
  height: number;
};

const GroupBarChart: React.FC<Props> = ({ influencerTimelineData, width, height }: Props) => {
  const getTimelineData = influencerTimelineData
    ? influencerTimelineData.data.map((data) => {
        const date = data.date.substring(0, 5);
        const timeline = data.scraped_timeline;
        return { date: date, timeline: timeline };
      })
    : [];

  const data: Array<{ date: string; hour: string; count: number }> = [];
  getTimelineData.forEach((item) => {
    item.timeline.forEach((item2) => {
      data.push({
        date: item.date,
        hour: `${item2.hour} hrs`,
        count: item2.count,
      });
    });
  });

  // const insert = [{ date: '05/20', hour: '6 hrs', count: 0 }];

  // data.splice(0, 0, ...insert);

  // const data = [
  //   {
  //     time: '6 hrs',
  //     date: '5/18',
  //     value: 18.9,
  //   },
  //   {
  //     time: '6 hrs',
  //     date: '5/19',
  //     value: 28.8,
  //   },
  //   {
  //     time: '6 hrs',
  //     date: '5/20',
  //     value: 39.3,
  //   },
  //   {
  //     time: '6 hrs',
  //     date: '5/21',
  //     value: 81.4,
  //   },
  //   {
  //     time: '6 hrs',
  //     date: '5/22',
  //     value: 47,
  //   },
  //   {
  //     time: '6 hrs',
  //     date: '5/23',
  //     value: 20.3,
  //   },
  //   {
  //     time: '6 hrs',
  //     date: '5/24',
  //     value: 24,
  //   },
  //   {
  //     time: '12 hrs',
  //     date: '5/18',
  //     value: 35.6,
  //   },
  //   {
  //     time: '12 hrs',
  //     date: '5/19',
  //     value: 12.4,
  //   },
  //   {
  //     time: '12 hrs',
  //     date: '5/20',
  //     value: 23.2,
  //   },
  //   {
  //     time: '12 hrs',
  //     date: '5/21',
  //     value: 34.5,
  //   },
  //   {
  //     time: '12 hrs',
  //     date: '5/22',
  //     value: 99.7,
  //   },
  //   {
  //     time: '12 hrs',
  //     date: '5/23',
  //     value: 52.6,
  //   },
  //   {
  //     time: '12 hrs',
  //     date: '5/24',
  //     value: 35.5,
  //   },
  //   {
  //     time: '18 hrs',
  //     date: '5/18',
  //     value: 37.4,
  //   },
  //   {
  //     time: '18 hrs',
  //     date: '5/19',
  //     value: 42.4,
  //   },
  //   {
  //     time: '18 hrs',
  //     date: '5/20',
  //     value: 44.4,
  //   },
  //   {
  //     time: '18 hrs',
  //     date: '5/21',
  //     value: 22.4,
  //   },
  //   {
  //     time: '18 hrs',
  //     date: '5/22',
  //     value: 40.4,
  //   },
  //   {
  //     time: '18 hrs',
  //     date: '5/23',
  //     value: 50.4,
  //   },
  //   {
  //     time: '18 hrs',
  //     date: '5/24',
  //     value: 30.4,
  //   },
  //   {
  //     time: '24 hrs',
  //     date: '5/18',
  //     value: 22.4,
  //   },
  //   {
  //     time: '24 hrs',
  //     date: '5/19',
  //     value: 42.4,
  //   },
  //   {
  //     time: '24 hrs',
  //     date: '5/20',
  //     value: 11.4,
  //   },
  //   {
  //     time: '24 hrs',
  //     date: '5/21',
  //     value: 40.4,
  //   },
  //   {
  //     time: '24 hrs',
  //     date: '5/22',
  //     value: 70.4,
  //   },
  //   {
  //     time: '24 hrs',
  //     date: '5/23',
  //     value: 76.4,
  //   },
  //   {
  //     time: '24 hrs',
  //     date: '5/24',
  //     value: 60.4,
  //   },
  // ];

  const config: ColumnConfig = {
    data,
    width: width,
    height: height,
    isGroup: true,
    xField: 'date',
    yField: 'count',
    seriesField: 'hour',
    columnWidthRatio: 0.5,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      hour: {
        alias: 'Time',
      },
      date: {
        alias: 'Date',
      },
      count: {
        alias: 'Influencers',
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      formatter: (text) => text,
    },
  };
  return <Column {...config} />;
};

export default GroupBarChart;
