import { LockOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Space, Tabs, Typography } from 'antd';
import { ReactComponent as MMILogo } from 'assets/MMI_Purple.svg';
import { FormikProps, useFormik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ILoginRequestPayload } from 'shared/interfaces/IAuth';
import { Button, Input, Text } from 'shared/theme/elements';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import * as yup from 'yup';

import {
  FlexBetween,
  LoginCard,
  LoginContainer,
  LoginForm,
  LoginWrapper,
  LogoContainer,
  RegisterForm,
  RememberMeCheckbox,
  TitleContainer,
} from './LoginPage.style';

type Props = {
  handleSubmit: (payload: ILoginRequestPayload) => void;
  loading?: boolean;
};
const LoginView: React.FC<Props> = ({ handleSubmit, loading }) => {
  const { TabPane } = Tabs;
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const initialValues: ILoginRequestPayload = {
    email: '',
    password: '',
  };

  const loginValidationSchema: yup.SchemaOf<ILoginRequestPayload> = yup.object().shape({
    email: yup.string().email('Invalid Email').required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const form: FormikProps<ILoginRequestPayload> = useFormik<ILoginRequestPayload>({
    initialValues,
    onSubmit: (values) => handleSubmit(values),
    validationSchema: loginValidationSchema,
  });

  return (
    <LoginWrapper>
      <LoginContainer>
        <LogoContainer>
          <MMILogo />
        </LogoContainer>
        <TitleContainer>
          <Typography.Text className="header__title">GLOBAL TEMPORTIA</Typography.Text>
        </TitleContainer>
        <LoginCard>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Login" key="1">
              <LoginForm
                onSubmit={(e) => {
                  e.preventDefault();
                  form.handleSubmit();
                }}
                method="POST"
              >
                <Space size="large" direction="vertical" className="form-space">
                  <Input
                    size="large"
                    name="email"
                    placeholder="Email"
                    prefix={<SettingOutlined />}
                    errorMessage={getErrorMessage(form.touched.email, form.errors.email)}
                    value={form.values.email}
                    onChange={form.handleChange}
                    $fullWidth
                  />
                  <Input
                    type="password"
                    size="large"
                    placeholder="Password"
                    prefix={<LockOutlined />}
                    $fullWidth
                    name="password"
                    value={form.values.password}
                    errorMessage={getErrorMessage(form.touched.password, form.errors.password)}
                    onChange={form.handleChange}
                  />
                  <FlexBetween>
                    <RememberMeCheckbox>Remember Me</RememberMeCheckbox>
                    <Link to={'#'} style={{ color: '#292F4C' }}>
                      Forgot your password?
                    </Link>
                  </FlexBetween>
                  <Button $fullwidth={true} htmlType="submit" loading={loading} disabled={loading}>
                    Login
                  </Button>
                </Space>
              </LoginForm>
            </TabPane>
            <TabPane tab="Register" key="2">
              <RegisterForm
                onSubmit={(e) => {
                  e.preventDefault();
                  //form.handleSubmit();
                }}
                //method="POST"
              >
                <Space size="large" direction="vertical" className="form-space">
                  {isRegisterSuccess ? (
                    <div style={{ textAlign: 'center' }}>
                      <Text $fontSize="14px" $fontWeight={400}>
                        We’ve sent a verification link to your email, verify your email to proceed.
                      </Text>
                      <Button
                        $fullwidth={true}
                        htmlType="submit"
                        // loading={loading}
                        // disabled={loading}
                        // onClick={() => setIsRegisterSuccess(true)}
                      >
                        Resend Email
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Input
                        size="large"
                        name="full_name"
                        placeholder="Full Name"
                        prefix={<SettingOutlined />}
                        errorMessage={getErrorMessage(form.touched.email, form.errors.email)}
                        value={form.values.email}
                        onChange={form.handleChange}
                        $fullWidth
                      />
                      <Input
                        size="large"
                        name="new_email"
                        placeholder="Email"
                        prefix={<MailOutlined />}
                        errorMessage={getErrorMessage(form.touched.email, form.errors.email)}
                        value={form.values.email}
                        onChange={form.handleChange}
                        $fullWidth
                      />
                      <Input
                        type="password"
                        size="large"
                        placeholder="Password"
                        prefix={<LockOutlined />}
                        $fullWidth
                        name="new_password"
                        value={form.values.password}
                        errorMessage={getErrorMessage(form.touched.password, form.errors.password)}
                        onChange={form.handleChange}
                      />
                      <FlexBetween>
                        <RememberMeCheckbox>
                          By creating an account you agree to our <a>Terms and Services</a> and <a>Privacy Policy</a>
                        </RememberMeCheckbox>
                      </FlexBetween>
                      <Button
                        $fullwidth={true}
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                        onClick={() => setIsRegisterSuccess(true)}
                      >
                        Create Account
                      </Button>
                    </>
                  )}
                </Space>
              </RegisterForm>
            </TabPane>
          </Tabs>
        </LoginCard>
        {/* Card */}
        {/* Card Header */}
        {/* Card Body Form */}
        {/* Footer */}
      </LoginContainer>
    </LoginWrapper>
  );
};

export default LoginView;
