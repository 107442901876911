import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { IKeywordRequestPayload, IKeywordResponsePayload } from 'shared/interfaces/iKeyword';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET } = useAxios();

export const useKeywordDao = () => {
  const getKeywords = async (payload?: IKeywordRequestPayload) => {
    const transformedPayload = transformPayload<IKeywordRequestPayload>(payload);

    const response = await GET<IKeywordResponsePayload>({
      url: ENDPOINTS.KEYWORDS,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  return {
    getKeywords,
  };
};
