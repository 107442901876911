import { AppstoreAddOutlined, BarChartOutlined, FormOutlined, UserOutlined } from '@ant-design/icons';
import { SidebarItemProps } from 'shared/interfaces/utils/ISidebar';

import { ROUTES } from './ROUTES';

export const SIDEBAR_MENU_LIST: SidebarItemProps[] = [
  {
    title: 'Posts',
    Icon: <FormOutlined />,
    to: ROUTES.TWEETS,
  },
  {
    title: 'Influencers',
    Icon: <UserOutlined />,
    to: ROUTES.INFLUENCERS,
  },
  {
    title: 'Summary',
    Icon: <BarChartOutlined />,
    to: ROUTES.SUMMARY,
  },
  {
    title: 'Custom Topics',
    Icon: <AppstoreAddOutlined />,
    to: ROUTES.CUSTOMTOPICS.OVERVIEW.LIST,
  },
];
