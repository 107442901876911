import React, { useState } from 'react';
import { Card, Checkbox } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'shared/theme/elements';
import { CardStyled } from './ColumnSetting.style';
import ClickAwayListener from 'react-click-away-listener';
import { TWEETLIST_COLUMNS, INFLUENCER_COLUMNS } from 'shared/constants/DATA';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

type Props = {
  checkColumns: CheckboxValueType[];
  handleColumns: (checkedValues: CheckboxValueType[]) => void;
  handleSelectAll: () => void;
  from: string;
};

const ColumnSettingView: React.FC<Props> = ({ checkColumns, handleColumns, handleSelectAll, from }: Props) => {
  // const { setAdditionalValue } = useContext(BreadcrumbContext);
  const [showSettingsCard, setShowSettingsCard] = useState(false);

  // useEffect(() => {
  //   setAdditionalValue(selectedTab);

  //   return () => {
  //     setAdditionalValue(undefined);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedTab]);

  const SettingsCard = () => (
    <CardStyled>
      <Card style={{ width: 'fit-content', height: 'auto' }}>
        <Checkbox.Group
          options={from === 'Posts' ? TWEETLIST_COLUMNS : from === 'Influencers' ? INFLUENCER_COLUMNS : []}
          value={checkColumns}
          onChange={handleColumns}
          style={{ display: 'flex', flexDirection: 'column' }}
        />
        <Button onClick={handleSelectAll} style={{ width: '100%', marginTop: '5px' }} variant="primary" size="small">
          Select All
        </Button>
      </Card>
    </CardStyled>
  );

  return (
    <div>
      <ClickAwayListener onClickAway={() => setShowSettingsCard(false)}>
        <div>
          <SettingOutlined
            style={{ position: 'absolute', right: '20px', top: '40px' }}
            onClick={() => setShowSettingsCard(!showSettingsCard)}
          />
          {showSettingsCard ? <SettingsCard /> : null}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ColumnSettingView;
