import { ICustomTopicKeywordListProps } from 'shared/interfaces/ICustomTopicKeywordListProps';

const stringMapper = (str: string[]) =>
  str.map((s) => {
    if (s.includes(' ')) {
      return `"${s.trim()}"`;
    } else {
      return `${s.trim()}`;
    }
  });

const stringMapperNot = (str: string[]) =>
  str.map((s) => {
    if (s.includes(' ')) {
      return `-"${s.trim()}"`;
    } else {
      return `-${s.trim()}`;
    }
  });

export const customTopicsKeywordsToBoolean = ({
  keywords,
  additionalKeywords,
  excludedKeywords,
  firstAdditionalKeywords,
  secondAdditionalKeywords,
  lastAdditionalKeywords,
}: ICustomTopicKeywordListProps) => {
  let booleanKeyword = '';
  let booleanAdditionalKeyword = '';
  let booleanExcludedKeyword = '';
  //
  let booleanFirstAdditionalKeyword = '';
  let booleanSecondAdditionalKeyword = '';
  let booleanThirdAdditionalKeyword = '';

  if (keywords.length > 0) {
    const mappedKeyword = stringMapper(keywords);
    booleanKeyword = mappedKeyword.join(' OR ');
  }
  if (additionalKeywords.length > 0) {
    booleanAdditionalKeyword = stringMapper(additionalKeywords).join(' OR ');
  }
  //
  if (firstAdditionalKeywords.length > 0) {
    booleanFirstAdditionalKeyword = stringMapper(firstAdditionalKeywords).join(' OR ');
  }
  //
  if (secondAdditionalKeywords.length > 0) {
    booleanSecondAdditionalKeyword = stringMapper(secondAdditionalKeywords).join(' OR ');
  }
  //
  if (lastAdditionalKeywords.length > 0) {
    booleanThirdAdditionalKeyword = stringMapper(lastAdditionalKeywords).join(' OR ');
  }

  if (excludedKeywords.length > 0) {
    booleanExcludedKeyword = stringMapperNot(excludedKeywords).join(' ');
  }

  return `${booleanKeyword} ${
    additionalKeywords.length > 0
      ? ` AND ${booleanAdditionalKeyword} ${
          booleanFirstAdditionalKeyword.length > 0 ? `AND ${booleanFirstAdditionalKeyword} ` : ''
        } ${booleanSecondAdditionalKeyword.length > 0 ? `AND ${booleanSecondAdditionalKeyword} ` : ''} ${
          booleanThirdAdditionalKeyword.length > 0 ? `AND ${booleanThirdAdditionalKeyword} ` : ''
        } `.trim()
      : ''
  }${excludedKeywords.length > 0 ? ` ${booleanExcludedKeyword} ` : ''} `;
};
