import { useQuery } from 'react-query';
import { useKeywordDao } from 'shared/dao/keyWordDao';
import { IKeywordRequestPayload, IKeywordResponsePayload } from 'shared/interfaces/iKeyword';

const { getKeywords: getKeywordsDao } = useKeywordDao();

export const useKeywordService = () => {
  const getKeywords = (payload?: IKeywordRequestPayload) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery<
      IKeywordResponsePayload,
      Error
    >(['keywords', payload], () => getKeywordsDao(payload));

    return {
      data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
      refetch,
    };
  };

  return {
    getKeywords,
  };
};
