import { Checkbox, Col, Row, Space, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { TableSorterDropdown, TableSorterIcon } from 'components/TableSorter/TableSorter';
import { CUSTOMTOPICSORT } from 'shared/constants/CUSTOM_TOPIC_SORT';
import { ICustomTopic } from 'shared/interfaces/ICustomTopic';
import { ITableColumn } from 'shared/interfaces/utils/ITable';
import { Button, Select } from 'shared/theme/elements';
import { toTitleCase } from 'shared/utils/toTitleCase';
import {
  CustomTopicLinkBlue,
  CustomTopicLinkGreen,
  CustomTopicLinkOrange,
  CustomTopicLinkPurple,
  CustomTopicLinkRed,
  CustomTopicLinkBlack,
} from './CustomTopicList.styled';
import {
  CustomTopicsFilter,
  CustomTopicsFilterSearch,
  CustomTopicSort,
  FilterSelectOptions,
} from './CustomTopicListContainer';

interface Props {
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  //
  onSort: (type: keyof CustomTopicSort, value: any) => void;
  sort: CustomTopicSort;
  //
  options: FilterSelectOptions;
  //
  filter: CustomTopicsFilter;
  onFilter: (type: keyof CustomTopicsFilter, value: any) => void;
  //
  onSearch: (type: keyof CustomTopicsFilterSearch, value: any) => void;
}

const CheckboxFilter = ({
  options,
  value,
  onChange,
  onApply,
  onClear,
}: {
  options: DefaultOptionType[] | any;
  value: any;
  onChange: (v: any) => void;
  onApply: () => void;
  onClear: () => void;
}) => (
  <div style={{ width: '200px', padding: '0.75rem', display: 'flex', flexDirection: 'column' }}>
    <Checkbox.Group onChange={onChange} value={value}>
      <Row gutter={[13, 13]}>
        {options?.map(({ value, label }) => (
          <Col span={24} key={value} style={{ width: 'auto' }}>
            {value !== '' && (
              <Checkbox key={value} value={value}>
                {label}
              </Checkbox>
            )}
          </Col>
        ))}
      </Row>
    </Checkbox.Group>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.25rem' }}>
      <Button size="middle" variant="outlined" onClick={onClear}>
        Reset
      </Button>
      <Button size="middle" onClick={onApply}>
        Apply
      </Button>
    </div>
  </div>
);

const CustomTopicSortItems = [
  {
    value: CUSTOMTOPICSORT.CUSTOMTOPICLATEST,
    label: 'Latest Topics',
  },
  {
    value: CUSTOMTOPICSORT.CUSTOMTOPICOLDEST,
    label: 'Oldest Topics',
  },
];

export const CUSTOMTOPICLIST_COLUMN = ({
  onEdit,
  onDelete,
  sort,
  onSort,
  options,
  filter,
  onFilter,
  onSearch,
}: Props): ITableColumn[] => [
  {
    key: 'country',
    title: 'Country',
    width: 300,
    render: (_, { country }: ICustomTopic) => (country ? toTitleCase(country) : country),
    filterDropdown: ({ confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: '260px' }}
          placeHolder="Select Country"
          size="middle"
          value={filter.country}
          options={options.country?.data}
          onChange={(value) => {
            if (!value) {
              clearFilters();
              onFilter('country', undefined);
            } else {
              onFilter('country', [value]);
              confirm();
            }
          }}
          onSearch={(value) => onSearch('country_name', value)}
          allowClear
          onClear={() => {
            clearFilters();
          }}
          filterOption={false}
          showSearch
          autoClearSearchValue
          loading={options.country?.loading}
          notFoundContent={options.country?.loading ? <Spin size="small" /> : null}
          isRequired
          $fullWidth
        ></Select>
      </div>
    ),
    filteredValue: filter.country,
    className: filter.country && filter.country?.length > 0 ? 'active-col' : '',
  },
  {
    key: 'keywords',
    title: 'Boolean Queries',
    width: '400px',
    render: (_, { keywords }) => {
      let blue = '';
      let green = '';
      let red = '';
      let purple = '';
      let orange = '';
      let black = '';

      const keywordsAdditional = keywords
        .slice(
          keywords.search('AND') === -1 ? keywords.length : keywords.search('AND') + 4,
          keywords.search(' -') === -1 ? keywords.length : keywords.search(' -') + 1,
        )
        .split('AND');

      // blue = keywords.slice(0, keywords.search('AND') === -1 ? keywords.length : keywords.search('AND') - 1);
      blue = keywords.slice(
        0,
        keywords.search('AND') === -1
          ? keywords.search(' -') === -1
            ? keywords.length
            : keywords.search(' -')
          : keywords.search('AND') - 1,
      );
      green = keywordsAdditional?.[0];
      purple = keywordsAdditional[1];
      orange = keywordsAdditional[2];
      black = keywordsAdditional[3];
      red = keywords.slice(keywords.search(' -') === -1 ? keywords.length : keywords.search(' -') + 1);

      return (
        <>
          <CustomTopicLinkBlue>{blue}</CustomTopicLinkBlue>
          {green?.length >= 1 && <CustomTopicLinkGreen> AND {green}</CustomTopicLinkGreen>}
          {purple?.length >= 1 && <CustomTopicLinkPurple> AND {purple}</CustomTopicLinkPurple>}
          {orange?.length >= 1 && <CustomTopicLinkOrange> AND {orange}</CustomTopicLinkOrange>}
          {black?.length >= 1 && <CustomTopicLinkBlack> AND {black}</CustomTopicLinkBlack>}
          {keywords.includes(' -') && <CustomTopicLinkRed>{red}</CustomTopicLinkRed>}
        </>
      );
    },
    filterDropdown: ({ confirm, clearFilters }) => (
      <TableSorterDropdown
        items={CustomTopicSortItems}
        current={sort.sort_by}
        onSelect={(value) => onSort('sort_by', value)}
        onConfirm={confirm}
        onClearFilters={clearFilters}
      />
    ),
    filterIcon: TableSorterIcon(
      sort.sort_by === CUSTOMTOPICSORT.CUSTOMTOPICLATEST
        ? 'ascend'
        : sort.sort_by === CUSTOMTOPICSORT.CUSTOMTOPICOLDEST
        ? 'descend'
        : undefined,
    ),
    className:
      sort.sort_by === CUSTOMTOPICSORT.CUSTOMTOPICLATEST || sort.sort_by === CUSTOMTOPICSORT.CUSTOMTOPICOLDEST
        ? 'active-col'
        : '',
  },
  {
    key: 'industry',
    title: 'Industry',
    width: 300,
    filterDropdown: ({ confirm, clearFilters, setSelectedKeys, selectedKeys }) => (
      <CheckboxFilter
        options={options.industry?.data}
        value={selectedKeys}
        onChange={(v) => {
          setSelectedKeys(v);
        }}
        onApply={() => {
          onFilter('industry', selectedKeys);
          confirm();
        }}
        onClear={() => {
          onFilter('industry', undefined);
          clearFilters();
        }}
      ></CheckboxFilter>
    ),
    filteredValue: filter.industry || [],
    className: filter.industry && filter.industry?.length > 0 ? 'active-col' : '',
  },
  {
    key: 'subtopics',
    title: 'Field (sub-topic)',
    width: 300,
    filterDropdown: ({ confirm, clearFilters, setSelectedKeys, selectedKeys }) => (
      <CheckboxFilter
        options={options.subtopics?.data}
        value={selectedKeys}
        onChange={(v) => {
          setSelectedKeys(v);
        }}
        onApply={() => {
          onFilter('subtopics', selectedKeys);
          confirm();
        }}
        onClear={() => {
          onFilter('subtopics', undefined);
          clearFilters();
        }}
      ></CheckboxFilter>
    ),
    filteredValue: filter.subtopics || [],
    className: filter.subtopics && filter.subtopics?.length > 0 ? 'active-col' : '',
  },
  {
    key: 'action',
    title: 'Actions',
    fixed: 'right',
    width: 200,
    render: (_, { _id }) => {
      return (
        <Space size="small">
          <Button key="edit" onClick={() => onEdit(_id)}>
            Edit
          </Button>
          <Button variant="outlined" key="delete" onClick={() => onDelete(_id)}>
            Delete
          </Button>
        </Space>
      );
    },
  },
];
