import { useMutation, useQuery } from 'react-query';
import { useCustomTopicDao } from 'shared/dao/CustomTopicDao';
import {
  ICustomTopicCreatePayload,
  ICustomTopicRequestPayload,
  ICustomTopicResponsePayload,
  ICustomTopicUpdatePayload,
} from 'shared/interfaces/ICustomTopic';

const {
  getCustomTopics: getCustomTopicsDao,
  getSingleCustomTopic: getSingleCustomTopicDao,
  deleteCustomTopic: deleteCustomTopicDao,
  createCustomTopic: createCustomTopicDao,
  updateCustomTopic: updateCustomTopicDao,
} = useCustomTopicDao();

export const useCustomTopicService = () => {
  const getCustomTopics = (payload?: ICustomTopicRequestPayload) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery<
      ICustomTopicResponsePayload,
      Error
    >(['custom-topics', payload], () => getCustomTopicsDao(payload));
    return {
      data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
      refetch,
    };
  };

  const getSingleCustomTopic = (id?: string) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData } = useQuery<
      ICustomTopicResponsePayload,
      Error
    >(['custom-topics', id], () => getSingleCustomTopicDao(id || ''), { enabled: !!id });

    return {
      data: data?.data,
      // data: data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
    };
  };
  const createCustomTopic = () => {
    const createMutation = useMutation((payload?: { data?: ICustomTopicCreatePayload }) =>
      createCustomTopicDao(payload),
    );

    return {
      createMutation,
    };
  };

  const updateCustomTopic = () => {
    const updateMutation = useMutation((payload?: { data?: ICustomTopicUpdatePayload }) =>
      updateCustomTopicDao(payload),
    );

    return {
      updateMutation,
    };
  };

  const deleteCustomTopic = () => {
    const deleteMutation = useMutation((id: string) => deleteCustomTopicDao(id));

    return {
      deleteMutation,
    };
  };

  return {
    getCustomTopics,
    getSingleCustomTopic,
    deleteCustomTopic,
    createCustomTopic,
    updateCustomTopic,
  };
};
