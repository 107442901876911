import { IKeyValue } from 'shared/interfaces/utils/IKeyValue';

export enum CUSTOMTOPICSORT {
  CUSTOMTOPICLATEST = '-date_created',
  CUSTOMTOPICOLDEST = '+date_created',
}

export const INDUSTRY_FILTER: IKeyValue[] = [
  {
    id: 'General',
    value: 'General',
  },
  {
    id: 'Healthcare',
    value: 'Healthcare',
  },
  {
    id: 'Technology',
    value: 'Technology',
  },
  {
    id: 'Finance',
    value: 'Finance',
  },
  {
    id: 'Political',
    value: 'Political',
  },
  {
    id: 'Social',
    value: 'Social',
  },
  {
    id: 'Economic',
    value: 'Economic',
  },
  {
    id: 'Environmental',
    value: 'Environmental',
  },
  {
    id: 'Sports',
    value: 'Sports',
  },
];

export const FIELD_FILTER: IKeyValue[] = [
  {
    id: 'General',
    value: 'General',
  },
  {
    id: 'Universal Healthcare',
    value: 'Universal Healthcare',
  },
  {
    id: 'Cybersecurity',
    value: 'Cybersecurity',
  },
  {
    id: 'Cryptocurrency',
    value: 'Cryptocurrency',
  },
  {
    id: 'Investing',
    value: 'Investing',
  },
  {
    id: 'National Security',
    value: 'National Security',
  },
  {
    id: 'Welfare',
    value: 'Welfare',
  },
  {
    id: 'Social Discrimination',
    value: 'Social Discrimination',
  },
  {
    id: 'Economy',
    value: 'Economy',
  },
];
