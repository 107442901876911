import styled from 'styled-components';

export const CardStyled = styled.div`
  position: absolute;
  filter: drop-shadow(0mm 1mm 5px #bfbfbf);
  top: 60px;
  right: 20px;
  z-index: 2;
  .ant-card-body {
    padding: 10px;
  }
`;
