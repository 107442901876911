import { useQuery } from 'react-query';
import { InfluencerTimelineDao } from 'shared/dao/InfluencerTimelineDao';
import {
  InfluencerTimelineRequestPayload,
  InfluencerTimelineResponsePayload,
} from 'shared/interfaces/IInfluencerTimeline';

export const useInfluencerTimelineService = () => {
  const { getInfluencerTimelineData: getInfluencerTimelineDataDao } = InfluencerTimelineDao();
  const getInfluencerTimeline = (payload?: InfluencerTimelineRequestPayload) => {
    const { data } = useQuery<InfluencerTimelineResponsePayload>(['influencer_timeline', payload], () =>
      getInfluencerTimelineDataDao(payload),
    );
    return {
      data,
    };
  };
  return {
    getInfluencerTimeline,
  };
};
