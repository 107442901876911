import React, { useEffect } from 'react';
import ColumnSettingView from './ColumnSettingView';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { TWEETLIST_COLUMNS, INFLUENCER_COLUMNS } from 'shared/constants/DATA';

type Props = {
  checkColumns: CheckboxValueType[];
  handleColumns: (checkedValues: CheckboxValueType[]) => void;
  setCheckedColumns: (checkedValues: CheckboxValueType[]) => void;
  from: string;
};

const ColumnSettingContainer: React.FC<Props> = ({ from, checkColumns, handleColumns, setCheckedColumns }: Props) => {
  useEffect(() => {
    if (from === 'Tweets') {
      setCheckedColumns(TWEETLIST_COLUMNS.map((column) => column.value));
    } else if (from === 'Influencers') {
      setCheckedColumns(INFLUENCER_COLUMNS.map((column) => column.value));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  const handleSelectAll = () => {
    if (from === 'Tweets') {
      setCheckedColumns(TWEETLIST_COLUMNS.map((column) => column.value));
    } else if (from === 'Influencers') {
      setCheckedColumns(INFLUENCER_COLUMNS.map((column) => column.value));
    }
  };

  return (
    <ColumnSettingView
      checkColumns={checkColumns}
      handleColumns={handleColumns}
      handleSelectAll={handleSelectAll}
      from={from}
    />
  );
};

export default ColumnSettingContainer;
