import { useQuery } from 'react-query';
import { InfluencersDao } from 'shared/dao/InfluencersDao';
import { InfluencersRequestPayload, InfluencersResponsePayload } from 'shared/interfaces/IInfluencers';

export const useInfluencerService = () => {
  const { getInfluencerData: getInfluencerDataDao } = InfluencersDao();

  const getInfluencer = (payload?: InfluencersRequestPayload) => {
    const { data, error, isLoading, isError } = useQuery<InfluencersResponsePayload>(
      ['influencers_list', payload],
      () => getInfluencerDataDao(payload),
    );
    return {
      data,
      error,
      isLoading,
      isError,
    };
  };

  return {
    getInfluencer,
  };
};
