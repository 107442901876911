import { useQuery } from 'react-query';
import { InfluencerScrapeDao } from 'shared/dao/InfluencerScrapeDao';
import { InfluencerScrapeRequestPayload, InfluencerScrapeResponsePayload } from 'shared/interfaces/IInfluencerScrape';

export const useInfluencerScrapeService = () => {
  const { getInfluencerScrapeData: getInfluencerScrapeDataDao } = InfluencerScrapeDao();
  const getInfluencerScrape = (payload?: InfluencerScrapeRequestPayload) => {
    const { data } = useQuery<InfluencerScrapeResponsePayload>(['influencer_scraped', payload], () =>
      getInfluencerScrapeDataDao(payload),
    );
    return {
      data,
    };
  };
  return {
    getInfluencerScrape,
  };
};
