export const TWEETLIST_COLUMNS: { label: string; value: string }[] = [
  { label: 'Twitter Username', value: 'twitter_id' },
  { label: 'Date Published', value: 'date_published' },
  { label: 'Tweet Text', value: 'tweet_text' },
  { label: 'Attachment', value: 'attachment' },
  { label: 'Tweet URL', value: 'tweet_url' },
  { label: 'Interests', value: 'interests' },
  { label: 'Country', value: 'country' },
  { label: 'Tweet Type', value: 'tweet_type' },
  { label: 'Influencer URL', value: 'influencer_url' },
  { label: 'Replies', value: 'replies' },
  { label: 'Retweets', value: 'retweets' },
  { label: 'Likes', value: 'likes' },
];

export const TWEET_TYPE: { text: string; value: string }[] = [
  { text: 'Tweeted', value: 'Tweeted' },
  { text: 'Retweeted', value: 'Retweeted' },
  { text: 'Quoted', value: 'Quoted' },
  { text: 'Replied To', value: 'Replied To' },
];

export const INFLUENCER_COLUMNS: { label: string; value: string }[] = [
  { label: 'Avatar', value: 'avatar' },
  { label: 'Influencer Name', value: 'influencer_name' },
  { label: 'Account Status', value: 'account_status' },
  { label: 'Twitter Username', value: 'username' },
  { label: 'Interests', value: 'interests' },
  { label: 'Biography', value: 'biography' },
  { label: 'Country', value: 'country' },
  { label: 'Influencer URL', value: 'influencer_url' },
  { label: 'Following', value: 'following' },
  { label: 'Followers', value: 'followers' },
  { label: 'Tweets', value: 'tweets' },
];

export const ACCOUNT_STATUS: { value: string; id: string }[] = [
  { value: 'All', id: 'ALL' },
  { value: 'Active', id: 'ACTIVE' },
  { value: 'Protected', id: 'PROTECTED' },
  { value: 'Banned', id: 'BANNED' },
  { value: 'Restricted', id: 'RESTRICTED' },
  { value: 'Suspended', id: 'SUSPENDED' },
];

export const VERIFICATION_STATUS: { value: string; id: string }[] = [
  { value: 'All', id: 'all' },
  { value: 'Verified', id: 'True' },
  { value: 'Unverified', id: 'False' },
];

// export const COUNTRIES: { value: string; id: string }[] = [
//   { value: 'Philippines', id: 'PHILIPPINES' },
//   { value: 'Singapore', id: 'SINGAPORE' },
//   { value: 'Malaysia', id: 'MALAYSIA' },
//   { value: 'Thailand', id: 'THAILAND' },
//   { value: 'Indonesia', id: 'INDONESIA' },
//   { value: 'Australia', id: 'AUSTRALIA' },
//   { value: 'Vietnam', id: 'VIETNAM' },
//   { value: 'India', id: 'INDIA' },
//   { value: 'USA', id: 'UNITED STATES' },
//   { value: 'Japan', id: 'JAPAN' },
// ];
