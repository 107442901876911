import styled from 'styled-components';
import { Form, Menu } from 'antd';

export const CardStyled = styled.div`
  position: absolute;
  width: fit-content;
  filter: drop-shadow(0mm 1mm 5px #bfbfbf);
  z-index: 2;
  .ant-card-body {
    padding: 10px;
  }
`;

export const FormStyled = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const SmallInputStyle = {
  width: '150px',
  margin: '5px 3px 0px 0px',
};

export const CardInputStyle = {
  width: '150px',
  marginBottom: '5px',
};

export const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const LeftContainer = styled.div`
  float: left;
`;
export const RightContainer = styled.div`
  float: right;
`;
export const TableContainer = styled.div`
  margin-top: 1.5rem;
  overflow-x: auto;
  width: 100%;
`;

export const CardWidth = {
  width: 'fit-content',
};

export const MenuStyled = styled(Menu.Item)`
  .ant-menu-title-content {
    width: 100%;
  }
  margin: 5px;
`;

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .__icon {
    cursor: pointer;
  }
`;
