import { useEffect, useState } from 'react';

const searchMap = new Map<string | object, object>();
const debounceMap = new Map<any, any>();

export interface ISearch<T> {
  setSearch: (key: keyof T, value: any) => void;
  resetSearch: () => void;
  search: T;
}

export interface ConfigOptionsProps {
  delay?: number;
}

interface ConfigProps<T extends object> {
  key?: string;
  options: {
    [key in keyof T]: ConfigOptionsProps;
  };
}

export function useSearch<T extends object>(search: T, config?: ConfigProps<T>): ISearch<T> {
  const data = searchMap.get(config?.key || search) as T;

  const [searchState, setSearchState] = useState<T>(data ?? search);
  const [searchDebouncedState, setDebouncedState] = useState<T>(data ?? search);

  /**
   * This will set the search values in state
   * @param key based on key of your object
   * @param value
   */
  function setSearch(key: keyof T, value: any) {
    const hasDebounce = debounceMap.get(key);

    if (hasDebounce) {
      clearTimeout(hasDebounce);
    }

    setSearchState((current) => {
      const newValue = { ...current, [key as string]: value };

      if (config?.options?.[key as keyof T]?.delay) {
        const debounce = setTimeout(() => {
          setDebouncedState(newValue);
          clearTimeout(debounce);
          debounceMap.delete(key);
        }, config?.options?.[key as keyof T]?.delay);

        debounceMap.set(key, debounce);
        return newValue;
      }

      setDebouncedState(newValue);
      return newValue;
    });
  }

  /**
   * This will reset the state values to default
   */
  const resetSearch = () => {
    setSearchState(search);
  };

  /**
   *  if un-mounted. the state object will save to map
   */
  useEffect(
    () => () => {
      searchMap.set(config?.key || search, searchState);
    },
    [config?.key, search, searchState],
  );

  return {
    setSearch,
    resetSearch,
    search: searchDebouncedState,
  };
}
