import { transformPayload } from 'shared/utils/transformPayload';
import { useAxios } from 'shared/hooks/useAxios';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import {
  ICustomTopicCreatePayload,
  ICustomTopicRequestPayload,
  ICustomTopicResponsePayload,
  ICustomTopicUpdatePayload,
} from 'shared/interfaces/ICustomTopic';

const { GET, POST, DELETE, PUT } = useAxios();

export const useCustomTopicDao = () => {
  const getCustomTopics = async (payload?: ICustomTopicRequestPayload) => {
    const transformedPayload = transformPayload<ICustomTopicRequestPayload>(payload);

    const response = await GET<ICustomTopicResponsePayload>({
      url: ENDPOINTS.CUSTOMTOPICS,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const getSingleCustomTopic = async (id?: string) => {
    const transformedPayload = transformPayload({ _id: id, limit: 1 });

    const response = await GET<ICustomTopicResponsePayload>({
      url: `${ENDPOINTS.CUSTOMTOPICS}/show`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const createCustomTopic = async (payload?: { data?: ICustomTopicCreatePayload }) => {
    const transformedPayload = transformPayload<ICustomTopicCreatePayload>(payload?.data);

    const formData = new FormData();
    formData.append('token', transformedPayload);

    const response = await POST({
      url: `${ENDPOINTS.CUSTOMTOPICS}/create`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const updateCustomTopic = async (payload?: { data?: ICustomTopicUpdatePayload }) => {
    const transformedPayload = transformPayload<ICustomTopicUpdatePayload>(payload?.data);

    const formData = new FormData();
    formData.append('token', transformedPayload);

    const response = await PUT({
      url: `${ENDPOINTS.CUSTOMTOPICS}/update`,
      data: formData,
      params: {
        token: formData.get('token'),
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const deleteCustomTopic = async (id: string) => {
    const transformedPayload = transformPayload({ _id: id });

    const response = await DELETE({
      url: `${ENDPOINTS.CUSTOMTOPICS}/delete`,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  return {
    getCustomTopics,
    getSingleCustomTopic,
    createCustomTopic,
    updateCustomTopic,
    deleteCustomTopic,
  };
};
