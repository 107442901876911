import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { transformPayload } from 'shared/utils/transformPayload';
import { InfluencerScrapeRequestPayload, InfluencerScrapeResponsePayload } from 'shared/interfaces/IInfluencerScrape';

const { GET } = useAxios();

export const InfluencerScrapeDao = () => {
  const getInfluencerScrapeData = async (payload?: InfluencerScrapeRequestPayload) => {
    const transformedPayload = transformPayload<InfluencerScrapeRequestPayload>(payload);
    const response = await GET<InfluencerScrapeResponsePayload>({
      url: `${ENDPOINTS.INFLUENCER_SCRAPE}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };
  return {
    getInfluencerScrapeData,
  };
};
