import styled from 'styled-components';
import { Form, Menu } from 'antd';

export const FormStyled = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

export const MenuStyled = styled(Menu.Item)`
  .ant-menu-title-content {
    width: 100%;
  }
  margin: 5px;
`;

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .__icon {
    cursor: pointer;
  }
`;
