import { useState } from 'react';
import { Card, Menu, Checkbox, Button, Space } from 'antd';
import PostsListView from './PostsListView';
import { ITableColumn } from 'shared/interfaces/utils/ITable';
import { usePagination } from 'shared/hooks/usePagination';
import { TWEETLIST_COLUMNS, TWEET_TYPE } from 'shared/constants/DATA';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useTweetListService } from 'shared/services/TweetListService';
import { useCountryService } from 'shared/services/CountryService';
import { RangeValue } from 'rc-picker/lib/interface';
import { FormInstance } from 'antd/es/form/Form';
import { format, parseJSON } from 'date-fns';
import { MenuStyled, SortContainer, HeadContainer } from './PostsList.style';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useInfluencerService } from 'shared/services/InfluencerService';
import Search from 'antd/lib/input/Search';
import { toTitleCase } from 'shared/utils/toTitleCase';

const PostsListContainer = () => {
  const [checkColumns, setCheckedColumns] = useState<CheckboxValueType[]>(
    TWEETLIST_COLUMNS.map((column) => column.value),
  );
  const { page, pageSize, handlePageChange, handlePageSizeChange } = usePagination();
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [searchedCountry, setSearchedCountry] = useState('');
  const [countryFilter, setCountryFilter] = useState<string[]>([]);
  const [repliesRange, setRepliesRange] = useState([0, 99999999999]);
  const [retweetsRange, setRetweetsRange] = useState([0, 99999999999]);
  const [likesRange, setLikesRange] = useState([0, 99999999999]);
  const [repliesInput, setRepliesInput] = useState('');
  const [retweetsInput, setRetweetsInput] = useState('');
  const [likesInput, setLikesInput] = useState('');
  const [dateRange, setDateRange] = useState({
    start: '2022-01-01',
    end: format(parseJSON(Date.now()), 'yyyy-MM-dd'),
  });
  const [dateStart, setDateStart] = useState('2022-01-01');
  const [dateEnd, setDateEnd] = useState(format(parseJSON(Date.now()), 'yyyy-MM-dd'));
  const [disabled, setDisabled] = useState(true);
  const [sortBy, setSortBy] = useState('tweet-publish-date-desc');
  const [username, setUsername] = useState('');
  const [searchedUser, setSearchedUser] = useState('');

  const { getCountry } = useCountryService();
  const { getTweetList } = useTweetListService();
  const { getInfluencer } = useInfluencerService();

  const { data, isLoading } = getTweetList({
    date_publish_start: dateStart,
    date_publish_end: dateEnd,
    sort_by: sortBy,
    retweet_count: retweetsRange,
    replies_count: repliesRange,
    likes_count: likesRange,
    country: selectedCountry,
    //data_source: 'netfeedr',
    tweet_author: searchedUser,
    page: page,
    size: pageSize,
  });

  const { data: influencers, isLoading: loading } = getInfluencer({
    social_poster_username: username,
    _id: '',
    social_poster_source: ['twitter'],
    scraped_from: '',
    social_poster_verified: '',
    social_poster_account_status: ['ACTIVE', 'PROTECTED', 'BANNED', 'RESTRICTED', 'SUSPENDED'],
    social_poster_country: [],
    social_poster_interests: [],
    social_poster_profile_url: '',
    social_poster_follower_count: [0, 99999999999],
    page,
    limit: pageSize,
  });

  const { data: countryData, isLoading: countryLoading } = getCountry({
    country_name: searchedCountry,
    limit: 20,
  });

  const country = countryData?.data?.map((country) => ({
    value: country.country_name,
    label: country.country_nice_name,
  }));

  let columns: ITableColumn[] = [];

  const cols = checkColumns.map((value) => {
    return TWEETLIST_COLUMNS.find((col) => col.value === value);
  });

  const handleSort = (sortBy: string) => {
    setSortBy(sortBy);
  };

  const setWidthAndFilters = (value: string) => {
    switch (value) {
      // case 'twitter_id': {
      //   return { width: '1%' };
      // }
      // case 'date_published': {
      //   return { width: '2%' };
      // }
      case 'tweet_text': {
        return { width: '20%' };
      }
      // case 'attachment': {
      //   return { width: '1%' };
      // }
      // case 'interests': {
      //   return { width: '5%' };
      // }
      case 'country': {
        return {
          //width: '1%',
          filterDropdown: () => (
            <Menu>
              <Search
                style={{ padding: '10px' }}
                placeholder="Search Country"
                onChange={(e) => setSearchedCountry(e.target.value)}
                loading={countryLoading}
              />
              <Checkbox.Group
                options={country}
                onChange={(value) => setCountryFilter(value as string[])}
                style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
                value={countryFilter}
              />
              <Menu.Divider key="divider" />
              <MenuStyled
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0px',
                }}
              >
                <Button
                  style={{ float: 'left' }}
                  type="link"
                  size="small"
                  onClick={() => {
                    setCountryFilter([]);
                    setSelectedCountry([]);
                    setSearchedCountry('');
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ float: 'right' }}
                  type="primary"
                  size="small"
                  onClick={() => {
                    const countryCases = countryFilter as string[];
                    countryFilter.forEach((country) => {
                      countryCases.push(toTitleCase(country));
                      countryCases.push(country.toLowerCase());
                    });
                    setSelectedCountry(countryFilter?.length > 0 ? countryCases : []);
                  }}
                >
                  OK
                </Button>
              </MenuStyled>
            </Menu>
          ),
        };
      }
      // case 'tweet_url': {
      //   return { width: '1%' };
      // }
      case 'tweet_type': {
        const filter = TWEET_TYPE.map((country) => {
          const text = country.text;
          const value = country.value;

          return { text: text, value: value };
        });

        const onFilter = (value, record) => record.tweet_type.includes(value);
        return { filters: filter, onFilter: onFilter };
      }
      // case 'influencer_url': {
      //   return { width: '1%' };
      // }
      case 'replies': {
        return { width: '1%' };
      }
      case 'retweets': {
        return { width: '1%' };
      }
      case 'likes': {
        return { width: '1%' };
      }
      default: {
        break;
      }
    }
  };

  const setSorters = (value: string) => {
    switch (value) {
      case 'twitter_id': {
        return (a, b) => a.twitter_id.localeCompare(b.twitter_id);
      }
      // case 'date_published': {
      //   return (a, b) => +new Date(a.date_published) - +new Date(b.date_published);
      // }
      // case 'replies': {
      //   return (a, b) => a.replies - b.replies;
      // }
      // case 'retweets': {
      //   return (a, b) => a.retweets - b.retweets;
      // }
      // case 'likes': {
      //   return (a, b) => a.likes - b.likes;
      // }
      default: {
        break;
      }
    }
  };

  const setTitle = (value: string, label: string) => {
    switch (value) {
      case 'twitter_id': {
        return label;
      }
      case 'date_published': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'tweet-publish-date-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('tweet-publish-date-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'tweet-publish-date-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('tweet-publish-date-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      case 'tweet_text': {
        return label;
      }
      case 'attachment': {
        return label;
      }
      case 'tweet_url': {
        return label;
      }
      case 'interests': {
        return label;
      }
      case 'country': {
        return label;
      }
      case 'tweet_type': {
        return label;
      }
      case 'influencer_url': {
        return label;
      }
      case 'replies': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'tweet-reply-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('tweet-reply-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'tweet-reply-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('tweet-reply-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      case 'retweets': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'retweet-count-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('retweet-count-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'retweet-count-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('retweet-count-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      case 'likes': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'likes-count-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('likes-count-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'likes-count-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('likes-count-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      case 'data_source': {
        return label;
      }
      default: {
        break;
      }
    }
  };

  cols.map((column) => {
    columns = [
      ...columns,
      {
        title: setTitle(column?.value as string, column?.label as string),
        key: column?.value as string,
        ...setWidthAndFilters(column?.value as string),
        sorter: setSorters(column?.value as string),
      },
    ];
  });

  const handleColumns = (checkedValues: CheckboxValueType[]) => {
    setCheckedColumns(checkedValues);
  };

  // const handleCountryChange = (value: any) => {
  //   setSelectedCountry(value);
  // };

  const handleDateRangeChange = (value: RangeValue<Date>) => {
    if (value) {
      const start = value[0]?.toJSON().substring(0, 10);
      const end = value[1]?.toJSON().substring(0, 10);
      setDateRange({ ...dateRange, start: start as string, end: end as string });
      // setDateStart(start as string);
      // setDateEnd(end as string);
    }
  };

  const handleUsernameChange = (value: any) => {
    setUsername(value);
  };

  const handleApplyFilter = (
    replies: number[],
    retweets: number[],
    likes: number[],
    date_range: { start: string; end: string },
    username: any,
  ) => {
    setDateStart(date_range.start ? date_range.start : '2022-01-01');
    setDateEnd(date_range.end ? date_range.end : format(parseJSON(Date.now()), 'yyyy-MM-dd'));
    setRepliesRange(replies.length > 0 ? replies : [0, 99999999999]);
    setRetweetsRange(retweets.length > 0 ? retweets : [0, 99999999999]);
    setLikesRange(likes.length > 0 ? likes : [0, 99999999999]);
    setSearchedUser(username);
  };

  const handleClear = (form: FormInstance<any>) => {
    form.resetFields();
    setDateStart('2022-01-01');
    setDateEnd(format(parseJSON(Date.now()), 'yyyy-MM-dd'));
    setRepliesRange([0, 99999999999]);
    setRetweetsRange([0, 99999999999]);
    setLikesRange([0, 99999999999]);
    setRepliesInput('');
    setRetweetsInput('');
    setLikesInput('');
    setDateRange({ ...dateRange, start: '', end: '' });
    setSearchedUser('');
    setUsername('');
  };

  return (
    <Card>
      <PostsListView
        data={data}
        columns={columns}
        page={page}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSizeChange}
        // selectedCountry={selectedCountry}
        // handleCountryChange={handleCountryChange}

        isLoading={isLoading}
        checkColumns={checkColumns}
        handleColumns={handleColumns}
        setCheckedColumns={setCheckedColumns}
        handleApplyFilter={handleApplyFilter}
        dateRange={dateRange}
        handleDateRangeChange={handleDateRangeChange}
        handleClear={handleClear}
        disabled={disabled}
        setDisabled={setDisabled}
        repliesInput={repliesInput}
        retweetsInput={retweetsInput}
        likesInput={likesInput}
        setRepliesInput={setRepliesInput}
        setRetweetsInput={setRetweetsInput}
        setLikesInput={setLikesInput}
        handleUsernameChange={handleUsernameChange}
        influencers={influencers}
        loading={loading}
      />
    </Card>
  );
};

export default PostsListContainer;
