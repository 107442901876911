import { Card, Col, Form, Progress, Row } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { useEffect } from 'react';
import { InfluencerPerDayResponsePayload } from 'shared/interfaces/IInfluencerPerDay';
import { InfluencerScrapeResponsePayload } from 'shared/interfaces/IInfluencerScrape';
import { DateRange, Text } from 'shared/theme/elements';
import BarChart from './BarChart';
import GroupBarChart from './GroupBarChart';
import { format, subDays } from 'date-fns';
import { InfluencerTimelineResponsePayload } from 'shared/interfaces/IInfluencerTimeline';

type Props = {
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  influencerScrapedData: InfluencerScrapeResponsePayload | undefined;
  influencerPerDayData: InfluencerPerDayResponsePayload | undefined;
  influencerTimelineData: InfluencerTimelineResponsePayload | undefined;
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      start: string;
      end: string;
    }>
  >;
  dateRange: {
    start: string;
    end: string;
  };
};

const SummaryView: React.FC<Props> = ({
  setDisabled,
  influencerScrapedData,
  influencerPerDayData,
  influencerTimelineData,
  setDateRange,
  dateRange,
}: Props) => {
  const [form] = Form.useForm();
  const isFieldsTouch = form.isFieldsTouched();

  useEffect(() => {
    if (isFieldsTouch) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isFieldsTouch, setDisabled]);

  return (
    <div>
      <Row justify="space-between">
        <Col span={8}>
          <Card style={{ width: '390px', height: '489px', marginTop: '20px' }}>
            <div style={{ marginBottom: '45px' }}>
              <Text $fontSize="16px">Total Influencers Scraped (last 24 hours)</Text>
            </div>

            <Progress
              type="circle"
              width={323}
              strokeWidth={12}
              percent={influencerScrapedData?.percentage}
              strokeLinecap="butt"
              format={() => (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Text $fontSize="14px" $color="#77D9AA" $fontWeight={700}>
                    {influencerScrapedData?.percentage}% SCRAPED
                  </Text>
                  <Text $fontSize="32px" $fontWeight={700}>
                    {influencerScrapedData?.past_24_hours_total_count}
                  </Text>
                  <Text $fontSize="14px" $color="#8C8C8C" $fontWeight={500} style={{ marginBottom: '10px' }}>
                    TOTAL: {influencerScrapedData?.total_influencer_count.toLocaleString()}
                  </Text>
                </div>
              )}
              strokeColor="#77D9AA"
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card style={{ height: '489px', marginTop: '20px' }}>
            <div style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
              <Text style={{ width: '100%' }} $fontSize="16px">
                Influencers Scraped Timeline
              </Text>
              <DateRange
                allowClear
                size="middle"
                placeholder={['Date From', 'Date To']}
                style={{ width: '300px', float: 'right' }}
                disabledDate={(current) => current > new Date()}
                defaultValue={[subDays(Date.now(), 6), subDays(Date.now(), 0)]}
                value={[new Date(dateRange.start), new Date(dateRange.end)]}
                onChange={(values: RangeValue<Date>) => {
                  setDateRange({
                    ...dateRange,
                    start: format(values?.[0] as Date, 'yyyy-MM-dd'),
                    end: format(values?.[1] as Date, 'yyyy-MM-dd'),
                  });
                }}
              />
            </div>
            <GroupBarChart influencerTimelineData={influencerTimelineData} width={896} height={355} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Card style={{ width: '100%', height: '452px', marginTop: '20px', paddingBottom: '5px' }}>
          <div style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
            <Text style={{ width: '100%' }} $fontSize="16px">
              Influencers Added per Day
            </Text>
            <DateRange
              allowClear
              //defaultValue={[subDays(Date.now(), 6), subDays(Date.now(), 0)]}
              defaultValue={[subDays(Date.now(), 6), subDays(Date.now(), 0)]}
              value={[new Date(dateRange.start), new Date(dateRange.end)]}
              size="middle"
              placeholder={['Date From', 'Date To']}
              style={{ width: '300px', float: 'right' }}
              onChange={(values: RangeValue<Date>) => {
                setDateRange({
                  ...dateRange,
                  start: format(values?.[0] as Date, 'yyyy-MM-dd'),
                  end: format(values?.[1] as Date, 'yyyy-MM-dd'),
                });
              }}
              disabledDate={(current) => current > new Date()}
            />
          </div>
          <BarChart influencerPerDayData={influencerPerDayData} width={1300} height={350} />
        </Card>
      </Row>
    </div>
  );
};

export default SummaryView;
