import { useQuery } from 'react-query';
import { TweetListDao } from 'shared/dao/TweetListDao';
import { TweetListRequestPayload, TweetListResponsePayload } from 'shared/interfaces/IArticles';

export const useTweetListService = () => {
  const { getTweetData: getTweetDataDao } = TweetListDao();
  const getTweetList = (payload?: TweetListRequestPayload) => {
    const { data, error, isLoading, isError } = useQuery<TweetListResponsePayload>(['tweetlist', payload], () =>
      getTweetDataDao(payload),
    );
    return {
      data,
      error,
      isLoading,
      isError,
    };
  };
  return {
    getTweetList,
  };
};
