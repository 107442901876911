export const ROUTES = {
  LOGIN: '/',
  TWEETS: '/tweets',
  INFLUENCERS: '/influencers',
  SUMMARY: '/summary',
  CUSTOMTOPICS: {
    OVERVIEW: {
      LIST: '/custom-topic/overview',
      CREATE: '/custom-topic/overview/add-custom-topic',
      EDIT: '/custom-topic/overview/edit',
    },
  },
};
