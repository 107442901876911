import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { transformPayload } from 'shared/utils/transformPayload';
import { CountriesRequestPayload, CountriesResponsePayload } from 'shared/interfaces/ICountries';

const { GET } = useAxios();

export const CountriesDao = () => {
  const getCountryData = async (payload?: CountriesRequestPayload) => {
    const transformedPayload = transformPayload<CountriesRequestPayload>(payload);
    const response = await GET<CountriesResponsePayload>({
      url: `${ENDPOINTS.COUNTRIES}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };
  return {
    getCountryData,
  };
};
