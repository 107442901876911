import { Button, Input, Select, Text } from 'shared/theme/elements';
import { Card, Row, Col, Space, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ROUTES } from 'shared/constants/ROUTES';
import { StyledTag } from 'pages/Summary/Summary.style';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ICustomTopic, ICustomTopicCreatePayload } from 'shared/interfaces/ICustomTopic';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { isEqual } from 'lodash';
import {
  AdditionalKeywordContainer,
  BigAdditionalKeywordButton,
  BigExcludedKeywordButton,
  BigKeywordButton,
  StringPreviewBlueSpan,
  StringPreviewContainer,
  StringPreviewGreenSpan,
  StringPreviewOtherAdditionalKeywordsSpan,
  StringPreviewOtherExcludedKeywordsSpan,
  StringPreviewOtherKeywordsSpan,
  StringPreviewParagraph,
  StringPreviewRedSpan,
  StringPreviewTitle,
} from './AddCustomTopic.styled';
import { AddCustomTopicPageSearch } from './AddCustomTopicContainer';
import { customTopicKeywordPreviewParser } from 'shared/utils/customTopicKeywordPreviewParser';
import { customTopicsKeywordsToBoolean } from 'shared/utils/customTopicKeywordsToBoolean';
import { useCustomTopicForm } from './AddCustomTopicForm';

interface Props {
  isEdit: boolean;
  isLoading?: boolean;
  countriesOptions: DefaultOptionType[];
  countrySearchInput: string;
  setCountrySearchInput: React.Dispatch<React.SetStateAction<string>>;
  isCountryLoading?: boolean;
  //
  keywordOptions: DefaultOptionType[];
  isKeywordLoading: boolean;
  onSearch: (key: keyof AddCustomTopicPageSearch, value: string | undefined) => void;
  //
  handleSubmit: (values: ICustomTopicCreatePayload) => void;
  customtopic?: ICustomTopic[] | any;
  //
}

const AddCustomTopicView = ({
  isEdit,
  countriesOptions,
  setCountrySearchInput,
  isCountryLoading,
  isLoading,
  //
  handleSubmit,
  customtopic,
}: //
//
//
Props) => {
  const { push } = useHistory();

  //KEYWORDS
  const [keywords, setKeywords] = useState<string[]>([]);
  const [additionalKeywords, setAddionalKeywords] = useState<string[]>([]);
  const [excludedKeywords, setExcludedKeywords] = useState<string[]>([]);

  // OTHER ADDITIONAL KEYWORDS
  const [firstAdditionalKeywords, setfirstAdditionalKeywords] = useState<string[]>([]);
  const [secondAdditionalKeywords, setSecondAdditionalKeywords] = useState<string[]>([]);
  const [lastAdditionalKeywords, setLastAdditionalKeywords] = useState<string[]>([]);

  //

  // TOGGLE BIG BUTTON SHOW/HIDE
  const [showFirstAdditionalKeywordsInput, setShowFirstAdditionalKeywordsInput] = useState<boolean>(true);
  const [showSecondAdditionalKeywordsInput, setShowSecondAdditionalKeywordsInput] = useState<boolean>(true);
  const [showLastAdditionalKeywordsInput, setShowLastAdditionalKeywordsInput] = useState<boolean>(true);

  const toggleFirstKeywordsButton = () => {
    setShowFirstAdditionalKeywordsInput(!showFirstAdditionalKeywordsInput);
    setfirstAdditionalKeywords([]);
  };

  const toggleSecondKeywordsButton = () => {
    setShowSecondAdditionalKeywordsInput(!showSecondAdditionalKeywordsInput);
    setSecondAdditionalKeywords([]);
  };

  const toggleLastKeywordsButton = () => {
    setShowLastAdditionalKeywordsInput(!showLastAdditionalKeywordsInput);
    setLastAdditionalKeywords([]);
  };

  // CLEAR OTHER ADDITIONAL KEYWORDS DATA

  // const clearFirstOtherKeywords = () => {
  //   setfirstAdditionalKeywords([]);
  // };

  // const clearSecondOtherKeywords = () => {
  //   setSecondAdditionalKeywords([]);
  // };
  // const clearLastOtherKeywords = () => {
  //   setLastAdditionalKeywords([]);
  // };

  //

  const [inputText, setInputText] = useState<{
    keyword: string;
    additionalKeyword: string;
    excludedKeyword: string;
    //
    firstAdditionalKeywords: string;
    secondAdditionalKeywords: string;
    lastAdditionalKeywords: string;
  }>({
    keyword: '',
    additionalKeyword: '',
    excludedKeyword: '',
    //
    firstAdditionalKeywords: '',
    secondAdditionalKeywords: '',
    lastAdditionalKeywords: '',
  });

  const [
    parsedKeywords,
    parsedAdditionalKeywords,
    parsedExcludedKeywords,
    //
    parsedFirstAdditionalKeywords,
    parsedSecondAdditionalKeywords,
    parsedLastAdditionalKeywords,
  ] = customTopicKeywordPreviewParser({
    keywords,
    additionalKeywords,
    excludedKeywords,
    firstAdditionalKeywords,
    secondAdditionalKeywords,
    lastAdditionalKeywords,
  });

  // GET TOTAL CHARACTERS OF TEXT
  const charLimit = 478;
  const charLimitCount =
    parsedKeywords.length +
    parsedAdditionalKeywords.length +
    parsedExcludedKeywords.length +
    parsedFirstAdditionalKeywords.length +
    parsedSecondAdditionalKeywords.length +
    parsedLastAdditionalKeywords.length;

  const goBack = () => {
    push(ROUTES.CUSTOMTOPICS.OVERVIEW.LIST);
  };

  const handleOnCancel = () => {
    goBack();
  };

  // HANDLE FORM and INITIAL VALUES

  const getInitialValues = () => {
    if (!customtopic) return undefined;

    const data = {
      ...customtopic,
    };

    return data;
  };

  const { form } = useCustomTopicForm({
    initialValue: getInitialValues(),
    onSubmit: (values) => {
      handleSubmit(values);
    },
    keywords,
  });
  //

  // TO SET KEYWORDS INTO FORMIK FORM
  useEffect(() => {
    if (
      keywords.length > 0 ||
      additionalKeywords.length > 0 ||
      excludedKeywords.length > 0 ||
      firstAdditionalKeywords.length > 0 ||
      secondAdditionalKeywords.length > 0 ||
      lastAdditionalKeywords.length > 0
    ) {
      form.setFieldValue(
        'keywords',
        customTopicsKeywordsToBoolean({
          keywords,
          additionalKeywords,
          firstAdditionalKeywords,
          secondAdditionalKeywords,
          lastAdditionalKeywords,
          excludedKeywords,
        })
          .trim()
          .replace(/\\/g, ''),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keywords,
    additionalKeywords,
    excludedKeywords,
    firstAdditionalKeywords,
    secondAdditionalKeywords,
    lastAdditionalKeywords,
  ]);

  const handleAddKeyword = (
    e: React.KeyboardEvent<HTMLInputElement>,
    type:
      | 'keyword'
      | 'additionalKeyword'
      | 'excludedKeyword'
      | 'firstAdditionalKeywords'
      | 'secondAdditionalKeywords'
      | 'lastAdditionalKeywords',
  ) => {
    if (e.key === 'Enter') {
      const count = (e.target as HTMLInputElement).value;

      if (inputText) {
        if (type === 'keyword') {
          if (!keywords.includes(inputText.keyword)) {
            if (charLimitCount < charLimit && count.length > 1) {
              setKeywords((curr) => [...curr, inputText.keyword]);
              setInputText({ ...inputText, keyword: '' });
            }
          }
        } else if (type === 'additionalKeyword') {
          if (!additionalKeywords.includes(inputText.additionalKeyword)) {
            if (charLimitCount < charLimit && count.length > 1) {
              setAddionalKeywords((curr) => [...curr, inputText.additionalKeyword]);
              setInputText({ ...inputText, additionalKeyword: '' });
            }
          }
        } else if (type === 'firstAdditionalKeywords') {
          if (!firstAdditionalKeywords.includes(inputText.firstAdditionalKeywords)) {
            if (charLimitCount < charLimit && additionalKeywords.length > 0 && count.length > 1) {
              setfirstAdditionalKeywords((curr) => [...curr, inputText.firstAdditionalKeywords]);
              setInputText({ ...inputText, firstAdditionalKeywords: '' });
            }
          }
        } else if (type === 'secondAdditionalKeywords') {
          if (!secondAdditionalKeywords.includes(inputText.secondAdditionalKeywords)) {
            if (charLimitCount < charLimit && additionalKeywords.length > 0 && count.length > 1) {
              setSecondAdditionalKeywords((curr) => [...curr, inputText.secondAdditionalKeywords]);
              setInputText({ ...inputText, secondAdditionalKeywords: '' });
            }
          }
        } else if (type === 'lastAdditionalKeywords') {
          if (!lastAdditionalKeywords.includes(inputText.lastAdditionalKeywords)) {
            if (charLimitCount < charLimit && additionalKeywords.length > 0 && count.length > 1) {
              setLastAdditionalKeywords((curr) => [...curr, inputText.lastAdditionalKeywords]);
              setInputText({ ...inputText, lastAdditionalKeywords: '' });
            }
          }
        } else {
          if (!excludedKeywords.includes(inputText.excludedKeyword)) {
            if (charLimitCount < charLimit && count.length > 1) {
              setExcludedKeywords((curr) => [...curr, inputText.excludedKeyword]);
              setInputText({ ...inputText, excludedKeyword: '' });
            }
          }
        }
      }
    }
  };

  const handleRemoveKeyword = (
    index: number,
    type:
      | 'keyword'
      | 'additionalKeyword'
      | 'excludedKeyword'
      | 'firstAdditionalKeywords'
      | 'secondAdditionalKeywords'
      | 'lastAdditionalKeywords',
  ) => {
    if (type === 'keyword') {
      const filteredKeywords = keywords.filter((_, i) => i !== index);
      setKeywords(filteredKeywords);
    } else if (type === 'additionalKeyword') {
      const filteredAdditionalKeywords = additionalKeywords.filter((_, i) => i !== index);
      setAddionalKeywords(filteredAdditionalKeywords);
    } else if (type === 'firstAdditionalKeywords') {
      const filteredAdditionalKeywords = firstAdditionalKeywords.filter((_, i) => i !== index);
      setfirstAdditionalKeywords(filteredAdditionalKeywords);
    } else if (type === 'secondAdditionalKeywords') {
      const filteredAdditionalKeywords = secondAdditionalKeywords.filter((_, i) => i !== index);
      setSecondAdditionalKeywords(filteredAdditionalKeywords);
    } else if (type === 'lastAdditionalKeywords') {
      const filteredAdditionalKeywords = lastAdditionalKeywords.filter((_, i) => i !== index);
      setLastAdditionalKeywords(filteredAdditionalKeywords);
    } else {
      const filteredExcludedKeywords = excludedKeywords.filter((_, i) => i !== index);
      setExcludedKeywords(filteredExcludedKeywords);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText({ ...inputText, [e.target.name]: e.target.value });
  };

  // SLICE KEYWORDS IN FORM IF IN EDIT MODE
  if (isEdit) {
    useEffect(() => {
      const keywords = form?.values?.keywords;

      const reg = /[`""~%^()*+=><']/g;

      if (keywords) {
        setKeywords(
          keywords
            .slice(
              0,
              keywords.search('AND') === -1
                ? keywords.search(' -') === -1
                  ? keywords.length
                  : keywords.search(' -')
                : keywords.search('AND') - 1,
            )
            .split('OR')
            .filter((word) => word !== 'OR')
            .map((word) => word.replace(reg, '')),
        );

        const keywordsAdditional = keywords
          .slice(
            keywords.search('AND') === -1 ? keywords.length : keywords.search('AND') + 4,
            keywords.search(' -') === -1 ? keywords.length - 1 : keywords.search(' -') + 1,
          )
          .split('AND')
          .filter((word) => word !== '');

        if (keywordsAdditional.length > 0) {
          setAddionalKeywords(
            keywordsAdditional[0]
              .split('OR')
              .filter((word) => word !== 'OR')
              .map((word) => word.replace(reg, '')),
          );
        }

        if (keywordsAdditional.length > 1) {
          setShowFirstAdditionalKeywordsInput(false);

          setfirstAdditionalKeywords(
            keywordsAdditional[1]
              .split('OR')
              .filter((word) => word !== 'OR')
              .map((word) => word.replace(reg, '')),
          );
        }

        if (keywordsAdditional.length > 2) {
          setShowSecondAdditionalKeywordsInput(false);

          setSecondAdditionalKeywords(
            keywordsAdditional[2]
              .split('OR')
              .filter((word) => word !== 'OR')
              .map((word) => word.replace(reg, '')),
          );
        }
        if (keywordsAdditional.length > 3) {
          setShowLastAdditionalKeywordsInput(false);

          setLastAdditionalKeywords(
            keywordsAdditional[3]
              .split('OR')
              .filter((word) => word !== 'OR')
              .map((word) => word.replace(reg, '')),
          );
        }

        // setExcludedKeywords(
        //   keywords
        //     .slice(keywords.search(' -') + 1, keywords.length)
        //     .split(' -')
        //     .map((word: string) => word.replace(reg, '')),
        // );

        if (keywords.includes(' -')) {
          setExcludedKeywords(
            keywords
              .slice(keywords.search(' -') + 2, keywords.length)
              .split(' -')
              .map((word: string) => word.replace(reg, '')),
          );
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card title="Details">
        <Row gutter={[16, 36]}>
          <Col xs={24} lg={12} xl={8}>
            {/* Country */}
            <Select
              label="Country"
              placeHolder="Search Country"
              size="middle"
              id="country"
              value={form.values.country ? form.values.country : undefined}
              options={countriesOptions}
              onChange={(value) => form.setFieldValue('country', value)}
              errorMessage={getErrorMessage(form.touched.country, form.errors.country)}
              // disabled={author.fetching || author.loading}
              onSearch={(value) => setCountrySearchInput(value as string)}
              onClear={() => {
                form.setFieldTouched('country', true);
              }}
              filterOption={false}
              showSearch
              allowClear
              autoClearSearchValue
              loading={isCountryLoading}
              notFoundContent={isCountryLoading ? <Spin size="small" /> : 'Not Found'}
              isRequired
              $fullWidth
            ></Select>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Industry */}
            <Input
              type="text"
              placeholder="Enter Industry"
              id="industry"
              name="industry"
              label="Publication Name"
              size="middle"
              value={form.values.industry}
              onChange={form.handleChange}
              autoComplete="off"
              // errorMessage={getErrorMessage(form.touched.author_name, form.errors.author_name)}
              $fullWidth
              // disabled={author.fetching || author.loading}
            ></Input>
          </Col>

          <Col xs={24} lg={12} xl={8}>
            {/* Field (Sub Topic) */}
            <Input
              type="text"
              placeholder="Enter Field"
              id="subtopics"
              name="subtopics"
              label="Field (Subtopic)"
              size="middle"
              value={form.values.subtopics}
              onChange={form.handleChange}
              autoComplete="off"
              // errorMessage={getErrorMessage(form.touched.author_name, form.errors.author_name)}
              $fullWidth
              // disabled={author.fetching || author.loading}
            ></Input>
          </Col>

          {/* STRING PREVIEW */}
          <Col xs={24}>
            <StringPreviewTitle>
              <Text $fontSize="14px" $fontWeight="light">
                Keyword String Preview Preview
              </Text>
              <Text $color="#b2b2b2" $fontSize="14px" $fontWeight="light">
                Character Limit: {`${charLimitCount}/478`}
              </Text>
            </StringPreviewTitle>
            <StringPreviewContainer>
              <StringPreviewParagraph>
                <StringPreviewBlueSpan>{parsedKeywords}</StringPreviewBlueSpan>
                <StringPreviewGreenSpan>{parsedAdditionalKeywords}</StringPreviewGreenSpan>
                <StringPreviewOtherKeywordsSpan>{parsedFirstAdditionalKeywords}</StringPreviewOtherKeywordsSpan>
                <StringPreviewOtherAdditionalKeywordsSpan>
                  {parsedSecondAdditionalKeywords}
                </StringPreviewOtherAdditionalKeywordsSpan>
                <StringPreviewOtherExcludedKeywordsSpan>
                  {parsedLastAdditionalKeywords}
                </StringPreviewOtherExcludedKeywordsSpan>
                <StringPreviewRedSpan>{parsedExcludedKeywords}</StringPreviewRedSpan>
              </StringPreviewParagraph>
            </StringPreviewContainer>
          </Col>

          {/* MAIN KEYWORDS */}

          <Col xs={24} lg={12} xl={8}>
            <Input
              type="text"
              size="middle"
              label="Main Keyword"
              placeholder="Enter main keywords here"
              isRequired
              name="keyword"
              onKeyUp={(e) => handleAddKeyword(e, 'keyword')}
              value={inputText.keyword}
              onChange={handleInputChange}
              $fullWidth
              errorMessage={getErrorMessage(form.touched.keywords && keywords.length <= 0, form.errors.keywords)}
              autoComplete="off"
            />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Input
              type="text"
              $fullWidth
              size="middle"
              label="Additional Keywords"
              placeholder="Enter additional keywords here"
              onKeyUp={(e) => handleAddKeyword(e, 'additionalKeyword')}
              value={inputText.additionalKeyword}
              name="additionalKeyword"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </Col>

          <Col xs={24} lg={12} xl={8}>
            <Input
              type="text"
              $fullWidth
              size="middle"
              label="Excluded Keywords"
              placeholder="Enter excluded keywords here"
              onKeyUp={(e) => handleAddKeyword(e, 'excludedKeyword')}
              value={inputText.excludedKeyword}
              name="excludedKeyword"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </Col>

          {/* MAIN KEYWORDS PREVIEW */}
          <Col xs={24} lg={12} xl={8}>
            {keywords.length > 0 && (
              <Space wrap={true}>
                {keywords.map((keyword, index) => (
                  <StyledTag
                    key={index}
                    closable
                    color={'#3771c8'}
                    closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                    onClose={(e) => {
                      e.preventDefault();
                      handleRemoveKeyword(index, 'keyword');
                    }}
                  >
                    {keyword}
                  </StyledTag>
                ))}
              </Space>
            )}
          </Col>

          {/************************/}

          {/* ADDITIONAL KEYWORDS */}

          <Col xs={24} lg={12} xl={8}>
            {additionalKeywords.length > 0 && (
              <Space wrap={true}>
                {additionalKeywords.map((keyword, index) => (
                  <StyledTag
                    key={index}
                    closable
                    color="#00CD6E"
                    closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                    onClose={(e) => {
                      e.preventDefault();
                      handleRemoveKeyword(index, 'additionalKeyword');
                    }}
                  >
                    {keyword}
                  </StyledTag>
                ))}
              </Space>
            )}
          </Col>

          {/* EXCLUDED KEYWORDS */}

          <Col xs={24} lg={12} xl={8}>
            {excludedKeywords.length > 0 && (
              <Space wrap={true}>
                {excludedKeywords.map((keyword, index) => (
                  <StyledTag
                    key={index}
                    closable
                    color="#F44336"
                    closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                    onClose={(e) => {
                      e.preventDefault();
                      handleRemoveKeyword(index, 'excludedKeyword');
                    }}
                  >
                    {keyword}
                  </StyledTag>
                ))}
              </Space>
            )}
          </Col>

          {/* ADDITIONAL KEYWORDS BIG BUTTON*/}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12} xl={8}>
            {/* TOGGLE FIRST KEYWORDS BTN */}
            {showFirstAdditionalKeywordsInput ? (
              <BigKeywordButton onClick={toggleFirstKeywordsButton}>
                <PlusOutlined />
                Add Other Additional Keyword
              </BigKeywordButton>
            ) : (
              <AdditionalKeywordContainer>
                <Input
                  type="text"
                  size="middle"
                  label="Other Additional Keyword"
                  placeholder="Enter Other Additional Keywords"
                  name="firstAdditionalKeywords"
                  onKeyUp={(e) => handleAddKeyword(e, 'firstAdditionalKeywords')}
                  value={inputText.firstAdditionalKeywords}
                  onChange={handleInputChange}
                  $fullWidth
                  disabled={additionalKeywords.length === 0}
                  autoComplete="off"
                />
                <br />
                {/* FIRST ADDITIONAL KEYWORDS PREVIEW */}
                {firstAdditionalKeywords.length > 0 && (
                  <Space wrap={true}>
                    {firstAdditionalKeywords.map((firstAdditionalKeywords, index) => (
                      <StyledTag
                        key={index}
                        closable
                        color={'#605AA6'}
                        closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                        onClose={(e) => {
                          e.preventDefault();
                          handleRemoveKeyword(index, 'firstAdditionalKeywords');
                        }}
                      >
                        {firstAdditionalKeywords}
                      </StyledTag>
                    ))}
                  </Space>
                )}
                <br />
                <br />

                <Button variant="primary" onClick={toggleFirstKeywordsButton}>
                  Delete
                </Button>
              </AdditionalKeywordContainer>
            )}
          </Col>
          <Col xs={24} lg={12} xl={8}>
            {/* TOGGLE OTHER ADDITIONAL KEYWORDS BTN */}
            {showSecondAdditionalKeywordsInput ? (
              <BigAdditionalKeywordButton onClick={toggleSecondKeywordsButton}>
                <PlusOutlined />
                Add Other Additional Keyword
              </BigAdditionalKeywordButton>
            ) : (
              <AdditionalKeywordContainer>
                <Input
                  type="text"
                  size="middle"
                  label="Other Additional Keyword"
                  placeholder="Enter Other Additional Keywords"
                  name="secondAdditionalKeywords"
                  onKeyUp={(e) => handleAddKeyword(e, 'secondAdditionalKeywords')}
                  value={inputText.secondAdditionalKeywords}
                  onChange={handleInputChange}
                  $fullWidth
                  disabled={additionalKeywords.length === 0}
                  autoComplete="off"
                />
                <br />
                {/* OTHER ADDITIONAL KEYWORDS PREVIEW */}
                {secondAdditionalKeywords.length > 0 && (
                  <Space wrap={true}>
                    {secondAdditionalKeywords.map((secondAdditionalKeywords, index) => (
                      <StyledTag
                        key={index}
                        closable
                        color={'#FFA500'}
                        closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                        onClose={(e) => {
                          e.preventDefault();
                          handleRemoveKeyword(index, 'secondAdditionalKeywords');
                        }}
                      >
                        {secondAdditionalKeywords}
                      </StyledTag>
                    ))}
                  </Space>
                )}
                <br />
                <br />
                <Button variant="primary" onClick={toggleSecondKeywordsButton}>
                  Delete
                </Button>
              </AdditionalKeywordContainer>
            )}
          </Col>
          <Col xs={24} lg={12} xl={8}>
            {/* TOGGLE LAST ADDITIONAL KEYWORDS*/}
            {showLastAdditionalKeywordsInput ? (
              <BigExcludedKeywordButton onClick={toggleLastKeywordsButton}>
                <PlusOutlined />
                Add Other Additional Keyword
              </BigExcludedKeywordButton>
            ) : (
              <AdditionalKeywordContainer>
                <Input
                  type="text"
                  size="middle"
                  label="Other Additional Keyword"
                  placeholder="Enter Other Additional Keywords"
                  name="lastAdditionalKeywords"
                  onKeyUp={(e) => handleAddKeyword(e, 'lastAdditionalKeywords')}
                  value={inputText.lastAdditionalKeywords}
                  onChange={handleInputChange}
                  $fullWidth
                  disabled={additionalKeywords.length === 0}
                  autoComplete="off"
                />
                <br />
                {/* LAST ADDITIONAL KEYWORDS PREVIEW */}
                {lastAdditionalKeywords.length > 0 && (
                  <Space wrap={true}>
                    {lastAdditionalKeywords.map((lastAdditionalKeywords, index) => (
                      <StyledTag
                        key={index}
                        closable
                        color={'black'}
                        closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                        onClose={(e) => {
                          e.preventDefault();
                          handleRemoveKeyword(index, 'lastAdditionalKeywords');
                        }}
                      >
                        {lastAdditionalKeywords}
                      </StyledTag>
                    ))}
                  </Space>
                )}
                <br />
                <br />
                <Button variant="primary" onClick={toggleLastKeywordsButton}>
                  Delete
                </Button>
              </AdditionalKeywordContainer>
            )}
          </Col>
        </Row>
      </Card>

      <Space size="middle">
        <Button variant="outlined" onClick={() => handleOnCancel()} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            form.handleSubmit();
          }}
          disabled={isEqual(form.values, form.initialValues)}
          loading={isLoading}
        >
          {isEdit ? 'Update' : 'Add Custom Topic'}
        </Button>
      </Space>
    </Space>
  );
};

export default AddCustomTopicView;
