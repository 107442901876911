import { IKeywordListProps } from 'shared/interfaces/utils/IKeywordListProps';

const stringMapper = (str: string[]) => str.map((s) => ` ${s} `);
export const CTSearchKeywordsToBoolean = ({ keywords, additionalKeywords, excludedKeywords }: IKeywordListProps) => {
  let booleanKeyword = '';
  let booleanAdditionalKeyword = '';
  let booleanExcludedKeyword = '';
  if (keywords.length > 0) {
    const mappedKeyword = stringMapper(keywords);
    booleanKeyword = mappedKeyword.join(' OR ');
  }
  if (additionalKeywords.length > 0) {
    booleanAdditionalKeyword = stringMapper(additionalKeywords).join(' AND ');
  }
  if (excludedKeywords.length > 0) {
    booleanExcludedKeyword = stringMapper(excludedKeywords).join(' NOT ');
  }

  return `${booleanKeyword}${additionalKeywords.length > 0 ? ` AND ${booleanAdditionalKeyword}` : ''}${
    excludedKeywords.length > 0 ? ` NOT ${booleanExcludedKeyword}` : ''
  }`;
};
