import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { transformPayload } from 'shared/utils/transformPayload';
import {
  InfluencerTimelineRequestPayload,
  InfluencerTimelineResponsePayload,
} from 'shared/interfaces/IInfluencerTimeline';

const { GET } = useAxios();

export const InfluencerTimelineDao = () => {
  const getInfluencerTimelineData = async (payload?: InfluencerTimelineRequestPayload) => {
    const transformedPayload = transformPayload<InfluencerTimelineRequestPayload>(payload);
    const response = await GET<InfluencerTimelineResponsePayload>({
      url: `${ENDPOINTS.INFLUENCER_TIMELINE}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };
  return {
    getInfluencerTimelineData,
  };
};
