import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Spin } from 'antd';
import { Input, Button, DateRange, Select } from 'shared/theme/elements';
import { Table } from 'shared/theme/elements/Table';
import { CardStyled, CardWidth, SmallInputStyle, CardInputStyle, TableContainer, FormStyled } from './PostsList.style';
import ClickAwayListener from 'react-click-away-listener';
import { ColumnSettingComponent } from 'pages/ColumnSetting';
import { ITableColumn, ITableData } from 'shared/interfaces/utils/ITable';
import { format, parseJSON } from 'date-fns';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useQueryClient } from 'react-query';
import { RangeValue } from 'rc-picker/lib/interface';
import { FormInstance } from 'antd/es/form/Form';
import { CountriesResponsePayload } from 'shared/interfaces/ICountries';
import { toTitleCase } from 'shared/utils/toTitleCase';
import { TweetListResponsePayload } from 'shared/interfaces/IArticles';

type Props = {
  columns: ITableColumn[];
  data: TweetListResponsePayload | undefined;
  handlePageChange: (page: number) => void;
  page: number;
  handlePageSize: (num: number) => void;
  pageSize: number;
  isLoading: boolean;
  checkColumns: CheckboxValueType[];
  handleColumns: (checkedValues: CheckboxValueType[]) => void;
  setCheckedColumns: (checkedValues: CheckboxValueType[]) => void;
  dateRange: { start: string; end: string };
  handleApplyFilter: (
    replies: number[],
    retweets: number[],
    likes: number[],
    date_range: { start: string; end: string },
    username,
  ) => void;
  handleDateRangeChange: (value: RangeValue<Date>) => void;
  handleClear: (form: FormInstance<any>) => void;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  repliesInput: string;
  retweetsInput: string;
  likesInput: string;
  setRepliesInput: (value: string) => void;
  setRetweetsInput: (value: string) => void;
  setLikesInput: (value: string) => void;
  countries?: CountriesResponsePayload;
  handleUsernameChange: (value: any) => void;
  influencers: any;
  loading: boolean;
};

type TablePaginationPosition = 'topRight' | 'bottomRight';

const PostsListView: React.FC<Props> = ({
  data,
  columns,
  handlePageChange,
  handlePageSize,
  isLoading,
  checkColumns,
  handleColumns,
  setCheckedColumns,
  handleApplyFilter,
  dateRange,
  handleDateRangeChange,
  handleClear,
  disabled,
  setDisabled,
  countries,
  handleUsernameChange,
  influencers,
  loading,
}: Props) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const isFieldsTouch = form.isFieldsTouched();
  useEffect(() => {
    queryClient.invalidateQueries('tweetlist');
  }, [queryClient]);

  useEffect(() => {
    if (isFieldsTouch) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isFieldsTouch, setDisabled]);

  const [showRepliesCard, setShowRepliesCard] = useState(false);

  const [showRetweets, setShowRetweets] = useState(false);

  const [showLikes, setShowLikes] = useState(false);
  const [top] = useState<TablePaginationPosition>('topRight');
  const [bottom] = useState<TablePaginationPosition>('bottomRight');

  const countryData = countries?.data.map((country) => {
    return { value: country.country_nice_name, id: country.country_name };
  });
  countryData?.unshift({ value: 'All', id: 'ALL' });

  const tableData: ITableData[] = [];

  data?.data.map((item, index) => {
    const user = item.tweet_author ? item.tweet_author.substring(1) : '';
    tableData.push({
      key: index,
      twitter_id: item.tweet_author ? item.tweet_author : '',
      date_published: `${format(parseJSON(item.tweet_publish_date), 'MMM d, yyyy hh:mm:ss aaa')}`,
      tweet_text: item.tweet_text,
      attachment: item.tweet_attachments,
      tweet_url: (
        <a href={`${item.tweet_url}`} target="_blank">
          {`${item.tweet_url}`}
        </a>
      ),
      interests: '',
      country: item.country ? toTitleCase(item.country) : '-',
      tweet_type: item.tweet_type,
      influencer_url: (
        <a href={`${item.tweet_author ? `https://twitter.com/${user}` : ''}`} target="_blank">
          {`${item.tweet_author ? `twitter.com/${user}` : ''}`}
        </a>
      ),
      //influencer_url: '',
      replies: item.tweet_reply_count,
      retweets: item.tweet_retweet_count,
      likes: item.tweet_like_count,
      //data_source: item.data_source,
      data_source: '-',
    });
  });

  const getUsername = influencers?.data ? influencers?.data.map((item) => item.social_poster_username) : null;
  const usernameList = getUsername ? getUsername.filter((user) => user != '') : null;

  const RepliesCard = () => (
    <CardStyled>
      <Card style={CardWidth}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item name="repliesMin">
            <Input
              required
              style={{ ...CardInputStyle, marginRight: '5px' }}
              placeholder="0"
              size="middle"
              maxLength={11}
            />
          </Form.Item>
          <Form.Item name="repliesMax">
            <Input required style={CardInputStyle} placeholder="99999999999" size="middle" maxLength={11} />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            style={{ float: 'right' }}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              form.setFieldsValue({
                replies: `${form.getFieldValue('repliesMin')}-${form.getFieldValue('repliesMax')}`,
                repliesMin: '',
                repliesMax: '',
              });
              setShowRepliesCard(false);
            }}
          >
            Set
          </Button>
        </Form.Item>
      </Card>
    </CardStyled>
  );
  const RetweetsCard = () => (
    <CardStyled>
      <Card style={CardWidth}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item name="retweetsMin">
            <Input
              required
              style={{ ...CardInputStyle, marginRight: '5px' }}
              placeholder="0"
              size="middle"
              maxLength={11}
            />
          </Form.Item>
          <Form.Item name="retweetsMax">
            <Input required style={CardInputStyle} placeholder="99999999999" size="middle" maxLength={11} />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            style={{ float: 'right' }}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              form.setFieldsValue({
                retweets: `${form.getFieldValue('retweetsMin')}-${form.getFieldValue('retweetsMax')}`,
                retweetsMin: '',
                retweetsMax: '',
              });
              setShowRetweets(false);
            }}
          >
            Set
          </Button>
        </Form.Item>
      </Card>
    </CardStyled>
  );
  const LikesCard = () => (
    <CardStyled>
      <Card style={CardWidth}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item name="likesMin">
            <Input
              required
              style={{ ...CardInputStyle, marginRight: '5px' }}
              placeholder="0"
              size="middle"
              maxLength={11}
            />
          </Form.Item>
          <Form.Item name="likesMax">
            <Input required style={CardInputStyle} placeholder="99999999999" size="middle" maxLength={11} />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            style={{ float: 'right' }}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              form.setFieldsValue({
                likes: `${form.getFieldValue('likesMin')}-${form.getFieldValue('likesMax')}`,
                likesMin: '',
                likesMax: '',
              });
              setShowLikes(false);
            }}
          >
            Set
          </Button>
        </Form.Item>
      </Card>
    </CardStyled>
  );
  return (
    <div>
      <FormStyled form={form}>
        <Row align="bottom">
          <ColumnSettingComponent
            from={'Posts'}
            checkColumns={checkColumns}
            setCheckedColumns={setCheckedColumns}
            handleColumns={handleColumns}
          />
          <label>
            <span>Platform</span>
            <Form.Item name="plaform">
              <Select
                style={{ ...SmallInputStyle, marginBottom: '20px' }}
                size="middle"
                options={[{ value: 'twitter', label: 'Twitter' }]}
                defaultValue="twitter"
              ></Select>
            </Form.Item>
          </label>
        </Row>
        <Row align="bottom">
          <Col span={20}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <label>
                <span>Search Tweet Text</span>
                <Form.Item name="tweet_text">
                  <Input
                    style={{ ...SmallInputStyle, width: '325px' }}
                    placeholder={`"Andrea" OR "COMELEC"`}
                    size="middle"
                  />
                </Form.Item>
              </label>
              <label>
                <span>Replies</span>
                <ClickAwayListener onClickAway={() => setShowRepliesCard(false)}>
                  <div>
                    <Form.Item name="replies">
                      <Input
                        style={SmallInputStyle}
                        placeholder="Set Range"
                        size="middle"
                        onClick={() => setShowRepliesCard(!showRepliesCard)}
                      />
                    </Form.Item>
                    {showRepliesCard ? <RepliesCard /> : null}
                  </div>
                </ClickAwayListener>
              </label>
              <label>
                <span>Retweets</span>
                <ClickAwayListener onClickAway={() => setShowRetweets(false)}>
                  <div>
                    <Form.Item name="retweets">
                      <Input
                        style={SmallInputStyle}
                        placeholder="Set Range"
                        size="middle"
                        onClick={() => setShowRetweets(!showRetweets)}
                      />
                    </Form.Item>

                    {showRetweets ? <RetweetsCard /> : null}
                  </div>
                </ClickAwayListener>
              </label>
              <label>
                <span>Likes</span>
                <ClickAwayListener onClickAway={() => setShowLikes(false)}>
                  <div>
                    <Form.Item name="likes">
                      <Input
                        style={SmallInputStyle}
                        placeholder="Set Range"
                        size="middle"
                        onClick={() => setShowLikes(!showLikes)}
                      />
                    </Form.Item>
                    {showLikes ? <LikesCard /> : null}
                  </div>
                </ClickAwayListener>
              </label>
              <label>
                <span>Date Published Range</span>
                <Form.Item name="date_range">
                  <DateRange
                    size="middle"
                    placeholder={['Date From', 'Date To']}
                    style={{ ...SmallInputStyle, width: '250px' }}
                    disabledDate={(current) => current > new Date()}
                    onChange={(selected) => handleDateRangeChange(selected)}
                  />
                </Form.Item>
              </label>
              <label>
                <span>Username</span>
                <Form.Item name="username">
                  <Select
                    style={{ ...SmallInputStyle, width: '160px' }}
                    size="middle"
                    placeHolder="Search Username"
                    options={
                      usernameList
                        ? usernameList.map((item) => {
                            return { value: item, id: item };
                          })
                        : null
                    }
                    onSearch={(value: any) => handleUsernameChange(value)}
                    showSearch
                    allowClear
                    filterOption={false}
                    autoClearSearchValue
                    loading={loading}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    $fullWidth
                  ></Select>
                </Form.Item>
              </label>
            </div>
          </Col>
          <Col span={4}>
            <div>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="primary"
                    size="small"
                    style={{ margin: '13px 5px 0px 5px', height: '32px' }}
                    onClick={() => {
                      const replies = form.getFieldValue('replies')
                        ? form.getFieldValue('replies').replace('-', ',').split(',').map(Number)
                        : [0, 99999999999];
                      const retweets = form.getFieldValue('retweets')
                        ? form.getFieldValue('retweets').replace('-', ',').split(',').map(Number)
                        : [0, 99999999999];
                      const likes = form.getFieldValue('likes')
                        ? form.getFieldValue('likes').replace('-', ',').split(',').map(Number)
                        : [0, 99999999999];
                      const username = form.getFieldValue('username');

                      handleApplyFilter(replies, retweets, likes, dateRange, username);
                    }}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    size="small"
                    style={{ margin: '13px 0px 0px 0px', height: '32px' }}
                    onClick={() => handleClear(form)}
                    disabled={disabled}
                  >
                    Clear
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </FormStyled>
      <Row>
        <TableContainer>
          <Table
            showSorterTooltip={false}
            loading={isLoading}
            data={tableData}
            columns={columns}
            // onChange={(pagination, filters, sorter, extra: { currentDataSource: [] }) => {
            //   //const size = pagination.pageSize;
            //   //console.log(pagination);
            //   // return {
            //   //   ...pagination,
            //   //   showTotal: (total, range) => `${range[0]}-${extra.currentDataSource.length - 1} of ${total} items`,
            //   // };
            //   // pagination.showTotal(
            //   //   (total, range) => `${range[0]}-${extra.currentDataSource.length - 1} of ${total} items`,
            //   // );
            //   handlePageSize()
            // }}
            pagination={{
              current: data?.meta?.pagination?.current_page,
              total: data?.meta?.pagination?.total_pages || 0,
              onChange: (newPage: number, currentPageSize: number) => {
                handlePageChange(newPage);
                handlePageSize(currentPageSize);
              },
              showTotal: (total, range) =>
                `${range[0].toLocaleString()}-${range[1].toLocaleString()} of ${total.toLocaleString()} results`,
              position: [top, bottom],
            }}
          />
        </TableContainer>
      </Row>
    </div>
  );
};

export default PostsListView;
