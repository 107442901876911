import { Space, Card as AntCard, Modal, Row, Col } from 'antd';
import { useMemo, useState } from 'react';
import { ICustomTopic } from 'shared/interfaces/ICustomTopic';
import { ITableData } from 'shared/interfaces/utils/ITable';
import { CustomTopicContainer, CustomTopicViewContainer, StyledTag, TextPreview } from './CustomTopicList.styled';
import { Input, Link } from 'shared/theme/elements';
import { ROUTES } from 'shared/constants/ROUTES';
import Table from 'shared/theme/elements/Table/Table';
import { CUSTOMTOPICLIST_COLUMN } from './CustomTopicListColumn';
import { IPagination } from 'shared/hooks/usePagination';
import {
  CustomTopicsFilter,
  CustomTopicsFilterSearch,
  CustomTopicSort,
  FilterSelectOptions,
} from './CustomTopicListContainer';
import { keywordPreviewParser } from 'shared/utils/keywordPreviewParser';
import { CloseCircleOutlined } from '@ant-design/icons';
import { FormikProps } from 'formik';
import { CTSearchKeywordsToBoolean } from 'shared/utils/CTSearchKeywordsToBoolean';

type Props = {
  data: ICustomTopic[];
  isDataLoading: boolean;
  pagination: IPagination;
  pageTotal: number;
  //
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  //
  onSort: (type: keyof CustomTopicSort, value: any) => void;
  sort: CustomTopicSort;
  //
  onSearch: (type: keyof CustomTopicsFilterSearch, value: any) => void;
  search: CustomTopicsFilterSearch;
  onCustomTopicSearch: (value: string) => void;
  //
  options: FilterSelectOptions;
  //
  filter: CustomTopicsFilter;
  onFilter: (type: keyof CustomTopicsFilter, value: any) => void;
  //
  addtionalFilterForm: FormikProps<any>;
};

const CustomTopicListView: React.FC<Props> = ({
  data,
  isDataLoading,
  pagination,
  pageTotal,
  onDelete,
  onEdit,
  //
  onSort,
  sort,
  //
  onCustomTopicSearch,
  //
  filter,
  onFilter,
  //
  options,
  //
  onSearch,
  //
  addtionalFilterForm,
}) => {
  const { handlePageChange, handlePageSizeChange, page } = pagination;

  //For Filter Search Modal

  const [showFilter] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [additionalKeywords, setAddionalKeywords] = useState<string[]>([]);
  const [excludedKeywords, setExcludedKeywords] = useState<string[]>([]);
  const [inputText, setInputText] = useState<{ keyword: string; additionalKeyword: string; excludedKeyword: string }>({
    keyword: '',
    additionalKeyword: '',
    excludedKeyword: '',
  });

  const [parsedKeywords, parsedAdditionalKeywords, parsedExcludedKeywords] = keywordPreviewParser({
    keywords,
    additionalKeywords,
    excludedKeywords,
  });

  const clearStateFields = () => {
    setKeywords([]);
    setAddionalKeywords([]);
    setExcludedKeywords([]);
  };

  const handleAddKeyword = (
    e: React.KeyboardEvent<HTMLInputElement>,
    type: 'keyword' | 'additionalKeyword' | 'excludedKeyword',
  ) => {
    if (e.key === 'Enter') {
      if (inputText) {
        if (type === 'keyword') {
          if (!keywords.includes(inputText.keyword)) {
            if (keywords.length < 10) {
              setKeywords((curr) => [...curr, inputText.keyword]);
              setInputText({ ...inputText, keyword: '' });
            }
          }
        } else if (type === 'additionalKeyword') {
          if (!additionalKeywords.includes(inputText.additionalKeyword)) {
            if (additionalKeywords.length < 10) {
              setAddionalKeywords((curr) => [...curr, inputText.additionalKeyword]);
              setInputText({ ...inputText, additionalKeyword: '' });
            }
          }
        } else {
          if (!excludedKeywords.includes(inputText.excludedKeyword)) {
            if (excludedKeywords.length < 10) {
              setExcludedKeywords((curr) => [...curr, inputText.excludedKeyword]);
              setInputText({ ...inputText, excludedKeyword: '' });
            }
          }
        }
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    addtionalFilterForm.setFieldValue(
      'keywords',
      CTSearchKeywordsToBoolean({ keywords, additionalKeywords, excludedKeywords }),
    );
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemoveKeyword = (index: number, type: 'keyword' | 'additionalKeyword' | 'excludedKeyword') => {
    if (type === 'keyword') {
      const filteredKeywords = keywords.filter((_, i) => i !== index);
      setKeywords(filteredKeywords);
    } else if (type === 'additionalKeyword') {
      const filteredAdditionalKeywords = additionalKeywords.filter((_, i) => i !== index);
      setAddionalKeywords(filteredAdditionalKeywords);
    } else {
      const filteredExcludedKeywords = excludedKeywords.filter((_, i) => i !== index);
      setExcludedKeywords(filteredExcludedKeywords);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText({ ...inputText, [e.target.name]: e.target.value });
  };
  //For Additional Filter

  const tableData = useMemo(() => {
    return data.map((result: ICustomTopic) => {
      return {
        ...result,
        key: result._id,
      };
    });
  }, [data]) as ITableData[];

  return (
    <>
      <CustomTopicViewContainer>
        <Space direction="vertical" style={{ width: 'inherit' }}>
          <AntCard>
            <CustomTopicContainer>
              <Space>
                {showFilter && (
                  <Space size={0}>
                    <Input
                      type="search"
                      allowClear
                      hasSearchButton
                      placeholder="Search Boolean Queries"
                      onChange={() => {
                        addtionalFilterForm.resetForm();
                        clearStateFields();
                        addtionalFilterForm.handleSubmit();
                      }}
                      // defaultValue={addtionalFilterForm.values.keywords}
                      onSearch={(value) => onCustomTopicSearch(value)}
                      name="keywords"
                      value={addtionalFilterForm.values.keywords}
                      onClick={showModal}
                      autoComplete="off"
                      // hasSearchButton
                    />
                  </Space>
                )}
                <Link type="link" to={ROUTES.CUSTOMTOPICS.OVERVIEW.CREATE}>
                  Add Custom Topic
                </Link>
              </Space>
            </CustomTopicContainer>

            <Table
              data={tableData}
              loading={isDataLoading}
              columns={CUSTOMTOPICLIST_COLUMN({ onEdit, onDelete, sort, onSort, options, filter, onFilter, onSearch })}
              pagination={{
                current: page,
                total: pageTotal,
                onChange: (newPage: number, currentPageSize: number) => {
                  handlePageChange(newPage);
                  handlePageSizeChange(currentPageSize);
                },
                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} results`,
                position: ['topRight', 'bottomRight'],
              }}
            ></Table>
          </AntCard>
        </Space>
      </CustomTopicViewContainer>

      {/* SHOW MODAL FOR SEARCH BOOLEAN QUERY */}
      <Modal title="Boolean Search Title and Content" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <TextPreview $fontSize="14px" $fontWeight="light">
              Keyword String Preview
            </TextPreview>
            {parsedKeywords && (
              <TextPreview $fontSize="14px" $color="#605AA6" $fontWeight="light" style={{ marginBottom: 0 }}>
                {parsedKeywords}
              </TextPreview>
            )}
            {parsedAdditionalKeywords && (
              <TextPreview $color="#00CD6E" $fontSize="14px" $fontWeight="light" style={{ marginBottom: 0 }}>
                {parsedAdditionalKeywords}
              </TextPreview>
            )}
            {parsedExcludedKeywords && (
              <TextPreview $color="#F44336" $fontSize="14px" $fontWeight="light" style={{ marginBottom: 0 }}>
                {parsedExcludedKeywords}
              </TextPreview>
            )}
          </Col>
          <Col span={24}>
            <Input
              type="text"
              size="middle"
              label="Main Keyword"
              isRequired
              name="keyword"
              onKeyUp={(e) => handleAddKeyword(e, 'keyword')}
              value={inputText.keyword}
              onChange={handleInputChange}
              $fullWidth
              // errorMessage={getErrorMessage(
              //   form.touched.keyword_string && keywords.length <= 0,
              //   form.errors.keyword_string,
              // )}
            />
          </Col>
          <Col span={24}>
            <>
              <TextPreview $color="#b2b2b2" $fontSize="14px" $fontWeight="light"></TextPreview>
              {keywords.length > 0 && (
                <Space wrap={true}>
                  {keywords.map((keyword, index) => (
                    <StyledTag
                      key={index}
                      closable
                      color="#605AA6"
                      closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                      onClose={(e) => {
                        e.preventDefault();
                        handleRemoveKeyword(index, 'keyword');
                      }}
                    >
                      {keyword}
                    </StyledTag>
                  ))}
                </Space>
              )}
            </>
          </Col>

          <Col span={24}>
            <Input
              type="text"
              $fullWidth
              size="middle"
              label="Additional Keywords"
              onKeyUp={(e) => handleAddKeyword(e, 'additionalKeyword')}
              value={inputText.additionalKeyword}
              name="additionalKeyword"
              onChange={handleInputChange}
            />
          </Col>
          <Col span={24}>
            <>
              <TextPreview $color="#b2b2b2" $fontSize="14px" $fontWeight="light"></TextPreview>
              {additionalKeywords.length > 0 && (
                <Space wrap={true}>
                  {additionalKeywords.map((keyword, index) => (
                    <StyledTag
                      key={index}
                      closable
                      color="#00CD6E"
                      closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                      onClose={(e) => {
                        e.preventDefault();
                        handleRemoveKeyword(index, 'additionalKeyword');
                      }}
                    >
                      {keyword}
                    </StyledTag>
                  ))}
                </Space>
              )}
            </>
          </Col>

          <Col span={24}>
            <Input
              type="text"
              $fullWidth
              size="middle"
              label="Excluded Keywords"
              onKeyUp={(e) => handleAddKeyword(e, 'excludedKeyword')}
              value={inputText.excludedKeyword}
              name="excludedKeyword"
              onChange={handleInputChange}
            />
          </Col>
          <Col span={24}>
            <>
              <TextPreview $color="#b2b2b2" $fontSize="14px" $fontWeight="light"></TextPreview>
              {excludedKeywords.length > 0 && (
                <Space wrap={true}>
                  {excludedKeywords.map((keyword, index) => (
                    <StyledTag
                      key={index}
                      closable
                      color="#F44336"
                      closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                      onClose={(e) => {
                        e.preventDefault();
                        handleRemoveKeyword(index, 'excludedKeyword');
                      }}
                    >
                      {keyword}
                    </StyledTag>
                  ))}
                </Space>
              )}
            </>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CustomTopicListView;
