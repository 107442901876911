import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCustomTopicService } from 'shared/services/CustomTopicService';
import AddCustomTopicView from './AddCustomTopicView';
import { useDebounce } from 'use-debounce';
import { useCountryService } from 'shared/services/CountryService';
import { ROUTES } from 'shared/constants/ROUTES';
import { useQueryClient } from 'react-query';
import { ICustomTopicForm } from 'shared/interfaces/ICustomTopic';
import { Alert, Spinner } from 'shared/theme/elements';
import { DefaultOptionType } from 'antd/lib/select';
import { useKeywordService } from 'shared/services/keywordService';
import { useDebouncedState } from 'shared/hooks/useDebouncedState';
import { Modal } from 'antd';

const { confirm } = Modal;
const { getSingleCustomTopic, createCustomTopic, updateCustomTopic } = useCustomTopicService();

export interface AddCustomTopicPageSearchOptions {
  keyword?: DefaultOptionType[];
}

export interface AddCustomTopicPageSearch {
  keyword?: string;
}

export interface AddCustomTopicPageSearchLoading {
  keyword?: boolean;
}

const AddCustomTopicContainer = () => {
  const { location, push } = useHistory();
  const locationState = location.state as any;
  const user = JSON.parse(localStorage.getItem('user') || '');
  const { createMutation } = createCustomTopic();
  const { updateMutation } = updateCustomTopic();
  const queryClient = useQueryClient();

  // COUNTRY
  const [countrySearchInput, setCountrySearchInput] = useState<string>('');
  const [countryKeyword] = useDebounce(countrySearchInput, 800);

  // // CUSTOM TOPIC ID
  const CUSTOM_TOPIC_ID = useMemo(() => {
    if (locationState?.id) return locationState.id;
    return undefined;
  }, [locationState]);

  // Get Single Custom Topic Service
  const { data: customTopicData, isLoading: customTopicIsLoading } = getSingleCustomTopic(CUSTOM_TOPIC_ID);

  // <== Country Service
  const { getCountry } = useCountryService();
  const { data: countriesData, isLoading: isCountryLoading } = getCountry({
    page: 1,
    limit: 10,
    country_name: countryKeyword,
  });

  //Keyword Search
  const { getKeywords } = useKeywordService();
  const [keywordSearch, setKeywordSearch] = useDebouncedState<string | undefined>(undefined, 800);
  const { data: keywordsData, isLoading: keywordsLoading } = getKeywords({
    keywords: keywordSearch,
  });
  //Keyword Search

  // Publication Service

  // Booleans
  const isEdit = useMemo(() => (CUSTOM_TOPIC_ID ? true : false), [CUSTOM_TOPIC_ID]);

  const goBack = () => {
    push(ROUTES.CUSTOMTOPICS.OVERVIEW.LIST, {
      filter: locationState?.filter,
      page: locationState?.page,
    });
  };

  // Custom Topic Submit

  const customTopicSubmit = (form: ICustomTopicForm) => {
    return new Promise((resolve, reject) => {
      if (isEdit) {
        updateMutation.mutateAsync(
          {
            data: {
              ...form,
              _id: CUSTOM_TOPIC_ID ? CUSTOM_TOPIC_ID : undefined,
              updated_by: user?._id,
            },
          },
          {
            onSuccess: () => {
              resolve(true);
              Alert.success('', 'Custom Topic Has Been Successfully Updated');
              queryClient.invalidateQueries('custom-topics');
              goBack();
            },
            onSettled: () => {
              (async () => await queryClient.refetchQueries('custom-topics'))();
            },
            onError: (error: any) => {
              reject(error);
              if (error?.response?.data?.server_response) {
                Alert.error('', error?.response?.data?.server_response);
              } else {
                Alert.error('', 'Failed to Update Custom Topic');
              }

              return;
            },
          },
        );
      } else {
        createMutation.mutate(
          {
            data: {
              ...form,
              created_by: user?._id,
            },
          },
          {
            onSuccess: () => {
              resolve(true);
              Alert.success('Custom Topic Created Successfully!');
              queryClient.invalidateQueries('custom-topics');
              goBack();
            },
            onSettled: () => {
              (async () => await queryClient.refetchQueries('custom-topics'))();
            },
            onError: (error: any) => {
              reject(error);
              if (error?.response?.data?.server_response) {
                Alert.error(error.response.data.server_response);
              } else {
                Alert.error('Something Went Wrong');
              }
            },
          },
        );
      }
    });
  };

  // Change text handle on Modal if Add or Update
  const addOrUpdate = () => {
    const changeTitle = isEdit ? 'Update Custom Topic' : 'Add Custom Topic';
    const changeContent = isEdit
      ? 'Are you sure you want to Update this Custom Topic?'
      : 'Are you sure you want to Add this Custom Topic?';

    return { changeTitle, changeContent };
  };

  const { changeTitle, changeContent } = addOrUpdate();

  const handleOnSubmit = (form: ICustomTopicForm) => {
    confirm({
      centered: true,
      title: changeTitle,
      content: changeContent,
      onOk: async () => customTopicSubmit(form),
      okButtonProps: {
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      cancelButtonProps: {
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      okText: 'Yes',
    });
  };

  // Country Options
  const countriesOptions = useMemo(() => {
    const results = countriesData?.data ? countriesData.data : [];
    return results.map((country) => {
      return {
        value: country.country_name,
        label: country.country_name,
      };
    });
  }, [countriesData]) as DefaultOptionType[];

  // Keyword Options
  const keywordOptions = useMemo(() => {
    const results = keywordsData?.data ? keywordsData.data : [];
    return results.map(({ keywords, keywords_label }) => ({
      value: keywords,
      label: keywords_label,
    }));
  }, [keywordsData?.data]) as DefaultOptionType[];

  const handleOnSearch = (key: keyof AddCustomTopicPageSearch, value: string | undefined) => {
    switch (key) {
      case 'keyword':
        setKeywordSearch(value);
        break;
    }
  };

  const isLoading = useMemo(() => {
    return customTopicIsLoading;
  }, [customTopicIsLoading]);

  // CUSTOM TOPIC FORM

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <AddCustomTopicView
          // customTopicSubmit={customTopicSubmit}
          isEdit={isEdit}
          isLoading={createMutation.isLoading || updateMutation.isLoading}
          countriesOptions={countriesOptions}
          countrySearchInput={countrySearchInput}
          setCountrySearchInput={setCountrySearchInput}
          isCountryLoading={isCountryLoading}
          //
          keywordOptions={keywordOptions}
          isKeywordLoading={keywordsLoading}
          onSearch={handleOnSearch}
          //
          handleSubmit={handleOnSubmit}
          customtopic={customTopicData}
        />
      )}
    </>
  );
};

export default AddCustomTopicContainer;
