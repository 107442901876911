import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { transformPayload } from 'shared/utils/transformPayload';
import { TweetListRequestPayload, TweetListResponsePayload } from 'shared/interfaces/IArticles';

const { GET } = useAxios();

export const TweetListDao = () => {
  const getTweetData = async (payload?: TweetListRequestPayload) => {
    const transformedPayload = transformPayload<TweetListRequestPayload>(payload);
    const response = await GET<TweetListResponsePayload>({
      url: `${ENDPOINTS.ARTICLES}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };
  return {
    getTweetData,
  };
};
