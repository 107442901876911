import { useState } from 'react';
import { Card, Menu, Checkbox, Button, Space } from 'antd';
import { INFLUENCER_COLUMNS, ACCOUNT_STATUS } from 'shared/constants/DATA';
import { ITableColumn } from 'shared/interfaces/utils/ITable';
import { usePagination } from 'shared/hooks/usePagination';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import InfluencersListView from './InfluencersListView';
import { useInfluencerService } from 'shared/services/InfluencerService';
import { useCountryService } from 'shared/services/CountryService';
import { FormInstance } from 'antd/es/form/Form';
import { toTitleCase } from 'shared/utils/toTitleCase';
// import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { HeadContainer, MenuStyled, SortContainer } from './InfluencersList.style';
import Search from 'antd/lib/input/Search';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

// type Props = {
//   checkColumns: CheckboxValueType[];
// };

const InfluencersListContainer = () => {
  const mapStatus = ACCOUNT_STATUS.map((status) => status.id).slice(1);
  const [checkColumns, setCheckedColumns] = useState<CheckboxValueType[]>(
    INFLUENCER_COLUMNS.map((column) => column.value),
  );
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [countryFilter, setCountryFilter] = useState<string[]>([]);
  const [accountStatus, setAccountStatus] = useState<string[]>(mapStatus);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [searchedUser, setSearchedUser] = useState('');
  const [followerRange, setFollowerRange] = useState({ min: 0, max: 99999999999 });
  const [sortBy, setSortBy] = useState<string | undefined>();
  const { page, pageSize, handlePageChange, handlePageSizeChange } = usePagination();

  const { getInfluencer } = useInfluencerService();
  const { getCountry } = useCountryService();

  const { data, isLoading } = getInfluencer({
    social_poster_username: searchedUser,
    _id: '',
    sort_by: sortBy,
    social_poster_source: ['twitter'],
    scraped_from: '',
    social_poster_verified: verificationStatus,
    social_poster_account_status: accountStatus,
    social_poster_country: selectedCountry,
    social_poster_interests: [],
    social_poster_profile_url: '',
    social_poster_follower_count: [followerRange.min, followerRange.max],
    page,
    limit: pageSize,
  });

  const [searchedCountry, setSearchedCountry] = useState('');

  const { data: countryData, isLoading: countryLoading } = getCountry({
    country_name: searchedCountry,
    limit: 20,
  });

  const country = countryData?.data?.map((country) => ({
    value: country.country_name,
    label: country.country_nice_name,
  }));

  const handleCountryChange = (value: any) => {
    setSelectedCountry(value);
  };

  const handleSort = (sortBy: string) => {
    setSortBy(sortBy);
  };

  const setFilters = (value: string) => {
    switch (value) {
      case 'country': {
        // const filter = COUNTRIES.map((country) => {
        //   const text = country.value;
        //   const value = country.id;

        //   return { text: text, value: value };
        // });

        // return COUNTRIES.map((country) => {
        //   const text = country.value;
        //   const value = country.id;
        //   return { text: text, value: value };
        // });
        return {
          filterDropdown: () => (
            <Menu>
              <Search
                style={{ padding: '10px' }}
                placeholder="Search Country"
                onChange={(e) => setSearchedCountry(e.target.value)}
                loading={countryLoading}
              />
              <Checkbox.Group
                options={country?.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(value) => setCountryFilter(value as string[])}
                style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}
                value={countryFilter}
              />
              <Menu.Divider key="divider" />
              <MenuStyled
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0px',
                }}
              >
                <Button
                  style={{ float: 'left' }}
                  type="link"
                  size="small"
                  onClick={() => {
                    setCountryFilter([]);
                    setSelectedCountry([]);
                    setSearchedCountry('');
                  }}
                >
                  Reset
                </Button>
                <Button
                  style={{ float: 'right', margin: '0px 5px' }}
                  type="primary"
                  size="small"
                  onClick={() => {
                    const countryCases = countryFilter as string[];
                    countryFilter.forEach((country) => {
                      countryCases.push(toTitleCase(country));
                      countryCases.push(country.toLowerCase());
                    });
                    setSelectedCountry(countryFilter?.length > 0 ? countryCases : []);
                  }}
                >
                  OK
                </Button>
              </MenuStyled>
            </Menu>
          ),
        };
      }
      default: {
        break;
      }
    }
  };

  const setTitle = (value: string, label: string) => {
    switch (value) {
      case 'avatar': {
        return label;
      }
      case 'influencer_name': {
        return label;
      }
      case 'account_status': {
        return label;
      }
      case 'username': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-username-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-username-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-username-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-username-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      case 'interests': {
        return label;
      }
      case 'biography': {
        return label;
      }
      case 'influencer_url': {
        return label;
      }
      case 'country': {
        return label;
      }
      case 'following': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-following-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-following-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-following-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-following-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      case 'followers': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-follower-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-follower-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-follower-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-follower-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      case 'tweets': {
        return (
          <HeadContainer>
            <span style={{ marginRight: '10px' }}>{label}</span>
            <Space>
              <SortContainer>
                <CaretUpOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-content-desc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-content-desc')}
                />
                <CaretDownOutlined
                  height={5}
                  style={{
                    fontSize: 12,
                    lineHeight: 0,
                    color: sortBy === 'social-poster-content-asc' ? '#1890FF' : '#bfbfbf',
                    margin: '-2px',
                  }}
                  onClick={() => handleSort('social-poster-content-asc')}
                />
              </SortContainer>
            </Space>
          </HeadContainer>
        );
      }
      default: {
        break;
      }
    }
  };

  let influencerColumns: ITableColumn[] = [];

  const cols = checkColumns.map((value) => {
    return INFLUENCER_COLUMNS.find((col) => col.value === value);
  });

  cols.map((column) => {
    influencerColumns = [
      ...influencerColumns,
      {
        title: setTitle(column?.value as string, column?.label as string),
        key: column?.value as string,
        ...setFilters(column?.value as string),
      },
    ];
  });

  // const infuencerData: ITableData[] = [];
  // for (let i = 0; i < 100; i++) {
  //   infuencerData.push({
  //     key: i,
  //     avatar: <Avatar size="large" icon={<UserOutlined />} />,
  //     influencer_name: (
  //       <span>
  //         Firstname Lastname
  //         <CheckCircleFilled style={{ margin: '10px', color: '#133774' }} />
  //       </span>
  //     ),
  //     account_status: 'Active',
  //     username: 'RohanNadkarni',
  //     influencer_url: (
  //       <a href="https://twitter.com/RohanNadkarni" target="_blank">
  //         twitter.com/RohanNadkarni
  //       </a>
  //     ),
  //     country: 'Philippines',
  //     following: '990',
  //     followers: '9,598',
  //     tweets: '21,059',
  //   });
  // }

  const handleColumns = (checkedValues: CheckboxValueType[]) => {
    setCheckedColumns(checkedValues);
  };

  const handleClear = (form: FormInstance<any>) => {
    form.resetFields();
    setFollowerRange({
      ...followerRange,
      min: 0,
      max: 99999999999,
    });
    setAccountStatus(mapStatus);
    setVerificationStatus('');
    setDisabled(true);
    setSortBy(undefined);
    setSearchedUser('');
  };
  const handleApplyFilter = (
    interests: string,
    range: string,
    country: string,
    status: string,
    verification: string,
    username: string,
  ) => {
    setFollowerRange({
      ...followerRange,
      min: parseInt(range[0]),
      max: parseInt(range[1]),
    });
    if (status === 'ALL') {
      setAccountStatus(mapStatus);
    } else {
      setAccountStatus([status]);
      setVerificationStatus(verification);
    }

    if (verification === 'all') {
      setVerificationStatus('');
    } else {
      setVerificationStatus(verification);
    }
    setSearchedUser(username);
    handlePageChange(1);
  };

  return (
    <Card>
      <InfluencersListView
        selectedCountry={selectedCountry}
        accountStatus={accountStatus}
        verificationStatus={verificationStatus}
        handleCountryChange={handleCountryChange}
        // handleAccountStatusChange={handleAccountStatusChange}
        // handleVerificationStatusChange={handleVerificationStatusChange}
        influencerColumns={influencerColumns}
        data={data}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSizeChange}
        checkColumns={checkColumns}
        setCheckedColumns={setCheckedColumns}
        handleColumns={handleColumns}
        disabled={disabled}
        setDisabled={setDisabled}
        handleClear={handleClear}
        handleApplyFilter={handleApplyFilter}
      />
    </Card>
  );
};

export default InfluencersListContainer;
