import React, { useState } from 'react';
import { format, parseJSON, subDays } from 'date-fns';
import SummaryView from './SummaryView';
import { useInfluencerScrapeService } from 'shared/services/InfluencerScrapeService';
import { useInfluencerPerDayService } from 'shared/services/InfluencerPerDayService';
import { useInfluencerTimelineService } from 'shared/services/InfluencerTimelineService';

const SummaryContainer = () => {
  const [disabled, setDisabled] = useState(true);
  const [dateRange, setDateRange] = useState({
    start: format(parseJSON(subDays(Date.now(), 6)), 'yyyy-MM-dd'),
    end: format(parseJSON(Date.now()), 'yyyy-MM-dd'),
  });

  const { getInfluencerScrape } = useInfluencerScrapeService();
  const { getInfluencerPerDay } = useInfluencerPerDayService();
  const { getInfluencerTimeline } = useInfluencerTimelineService();

  const { data: influencerScrapedData } = getInfluencerScrape();
  const { data: influencerPerDayData } = getInfluencerPerDay({
    date_publish_start: dateRange.start,
    date_publish_end: dateRange.end,
  });
  const { data: influencerTimelineData } = getInfluencerTimeline({
    date_publish_start: dateRange.start,
    date_publish_end: dateRange.end,
  });

  return (
    <SummaryView
      disabled={disabled}
      setDisabled={setDisabled}
      influencerScrapedData={influencerScrapedData}
      influencerPerDayData={influencerPerDayData}
      setDateRange={setDateRange}
      dateRange={dateRange}
      influencerTimelineData={influencerTimelineData}
    />
  );
};

export default SummaryContainer;
