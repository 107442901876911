import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import CustomRoute from './CustomRoute';
import { PostsListPage } from 'pages/PostsList';
import { InfluencersListPage } from 'pages/InfluencersList';
import { SummaryPage } from 'pages/Summary';
import { CustomTopicListPage } from 'pages/CustomTopic/List';
import { AddCustomTopicPage } from 'pages/CustomTopic/AddCustomTopic';
import { LoginPage } from 'pages/LoginPage';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.LOGIN} component={LoginPage} />
      {/* <Route exact path="/">
        <Redirect to={ROUTES.TWEETS} />
      </Route> */}
      <CustomRoute exact path={ROUTES.TWEETS} component={PostsListPage} />
      <CustomRoute exact path={ROUTES.INFLUENCERS} component={InfluencersListPage} />
      <CustomRoute exact path={ROUTES.SUMMARY} component={SummaryPage} />
      <CustomRoute exact path={ROUTES.CUSTOMTOPICS.OVERVIEW.LIST} component={CustomTopicListPage} />
      <CustomRoute exact path={ROUTES.CUSTOMTOPICS.OVERVIEW.CREATE} component={AddCustomTopicPage} />
      <CustomRoute exact path={ROUTES.CUSTOMTOPICS.OVERVIEW.EDIT} component={AddCustomTopicPage} />
    </Switch>
  );
};

export default Routes;
