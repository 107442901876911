import { FormikProps, useFormik } from 'formik';
import { ICustomTopic, ICustomTopicForm } from 'shared/interfaces/ICustomTopic';
import * as yup from 'yup';

interface Props {
  onSubmit: (values: ICustomTopicForm) => void;
  keywords: string[];
  initialValue?: ICustomTopic;
}

export const useCustomTopicForm = ({ onSubmit, keywords, initialValue }: Props) => {
  const getInitialValue = (
    key: keyof ICustomTopic,
    defaultValue: any,
    cb?: (key: string, record: ICustomTopic) => any,
  ) => {
    if (initialValue) {
      if (cb) {
        const value = cb(key, initialValue);
        return value;
      }

      if (!initialValue?.[key]) {
        return defaultValue;
      }

      return initialValue[key];
    } else {
      return defaultValue;
    }
  };

  //
  const CustomTopicSchema: yup.SchemaOf<ICustomTopicForm> = yup.object().shape({
    country: yup.string().required('Country is required'),
    industry: yup.string(),
    keywords: yup.string().test('has-keywords', 'Main Keyword is required', () => {
      return keywords.length > 0;
    }),
    subtopics: yup.string(),
  });
  //

  //
  const initialValues: ICustomTopicForm = {
    country: getInitialValue('country', 'PHILIPPINES'),
    industry: getInitialValue('industry', ''),
    keywords: getInitialValue('keywords', ''),
    subtopics: getInitialValue('subtopics', ''),
  };
  //

  const handleBeforeSubmit = (values: ICustomTopicForm): ICustomTopicForm => {
    const data: ICustomTopicForm = {
      ...values,
    };

    return data;
  };

  const handleOnSubmit = (values: ICustomTopicForm) => {
    // here: handle data before submit
    const data = handleBeforeSubmit(values);

    onSubmit(data as ICustomTopicForm);
  };

  const form: FormikProps<ICustomTopicForm> = useFormik<ICustomTopicForm>({
    initialValues,
    onSubmit: (values) => handleOnSubmit(values as ICustomTopicForm),
    validationSchema: CustomTopicSchema,
    enableReinitialize: true,
  });

  return {
    form,
  };
};
