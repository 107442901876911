import { IKeywordListProps } from 'shared/interfaces/utils/IKeywordListProps';

const mappedString = (string: string[]) => string.map((str) => `"${str}"`);

export const keywordPreviewParser = ({ keywords, additionalKeywords, excludedKeywords }: IKeywordListProps) => {
  let parsedKeyword = '';
  let parsedAdditionalKeywords = '';
  let parsedExcludedKeywords = '';
  if (keywords.length > 0) {
    const mappedKeywords = mappedString(keywords);
    parsedKeyword = `(${mappedKeywords.join(' OR ')})`;
  }

  if (additionalKeywords.length > 0) {
    const mappedAdditionalKeywords = mappedString(additionalKeywords);
    parsedAdditionalKeywords = `AND (${mappedAdditionalKeywords.join(' OR ')})`;
  }

  if (excludedKeywords.length > 0) {
    const mappedExcludedKeywords = mappedString(excludedKeywords);
    parsedExcludedKeywords = `AND NOT (${mappedExcludedKeywords.join(' OR ')})`;
  }

  return [parsedKeyword, parsedAdditionalKeywords, parsedExcludedKeywords];
};
