import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { transformPayload } from 'shared/utils/transformPayload';
import { InfluencersRequestPayload, InfluencersResponsePayload } from 'shared/interfaces/IInfluencers';

const { GET } = useAxios();

export const InfluencersDao = () => {
  const getInfluencerData = async (payload?: InfluencersRequestPayload) => {
    const transformedPayload = transformPayload<InfluencersRequestPayload>(payload);
    const response = await GET<InfluencersResponsePayload>({
      url: `${ENDPOINTS.INFLUENCERS}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };
  return {
    getInfluencerData,
  };
};
