import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { IconContainer, SorterDropdownContainer, SorterDropdownItem } from './TableSorter.styled';

type SortOrder = 'ascend' | 'descend' | 'all';

type ValueOf<T> = T[keyof T];

export interface TableSorterItem {
  value: string;
  label: string;
}

export interface TableSorterProps {
  items?: TableSorterItem[];
  current?: ValueOf<TableSorterItem>;
  onSelect?: (value?: SelectValue) => void;
  onConfirm?: (param?: FilterConfirmProps) => void;
  onClearFilters?: () => void;
}

export const TableSorterDropdown = ({ items, current, onSelect, onConfirm, onClearFilters }: TableSorterProps) => {
  const handleOnItemSelect = (value: SelectValue) => {
    if (onSelect) {
      if (value === current || isEmpty(value)) {
        onSelect(undefined);
        if (onClearFilters) onClearFilters();
        return;
      }

      onSelect(value);
      if (onConfirm) onConfirm();
    }
  };

  const hasItem = useMemo(() => {
    if (current) {
      const values = items?.map(({ value }) => value);
      return values?.includes(current);
    }
    return false;
  }, [current, items]);

  return (
    <SorterDropdownContainer>
      {items?.map(({ value, label }) => (
        <SorterDropdownItem
          key={value}
          role="button"
          aria-selected={hasItem && current === value ? 'true' : 'false'}
          onClick={() => handleOnItemSelect(value)}
        >
          {label}
        </SorterDropdownItem>
      ))}
    </SorterDropdownContainer>
  );
};

export const TableSorterIcon = (order?: 'ascend' | 'descend' | 'all'): ((filtered: boolean) => React.ReactNode) => {
  return (filtered: boolean) => (
    <IconContainer order={order} filtered={filtered}>
      <div className="wrapper">
        <CaretUpOutlined />
        <CaretDownOutlined />
      </div>
    </IconContainer>
  );
};

export interface TableSorterCustomProps {
  items?: TableSorterItem[];
  current?: ValueOf<TableSorterItem>;
  onSelect?: (value?: SelectValue) => void;
  onConfirm?: (param?: FilterConfirmProps) => void;
  onClearFilters?: () => void;
}

export const TableSorterCustomDropdown = ({
  items,
  current,
  onSelect,
  onConfirm,
  onClearFilters,
}: TableSorterCustomProps) => {
  const [menuItems, setMenuItems] = useState([
    {
      key: 'ascend',
      label: 'Ascending',
    },
    {
      key: 'descend',
      label: 'Descending',
    },
  ]);
  const [selected, setSelected] = useState<SelectValue | SortOrder | undefined>();

  const handleOnItemSelect = (value: SelectValue) => {
    if (value === current || isEmpty(value)) {
      setSelected(undefined);
      if (onSelect) onSelect(undefined);
      if (onClearFilters) onClearFilters();
      return;
    }

    if (onSelect) onSelect(value);
    setSelected(value);
    if (onConfirm) onConfirm();
  };

  const menu = (
    <Menu
      selectable
      items={menuItems}
      selectedKeys={selected ? [selected as string] : []}
      onClick={(val) => handleOnItemSelect(val.key)}
    ></Menu>
  );

  useEffect(() => {
    if (current) {
      setSelected(current);
    }
  }, [current]);

  useEffect(() => {
    if (items || !isEmpty(items)) {
      setMenuItems(items ? items?.map((item) => ({ key: item.value, label: item.label })) : []);
    }
  }, [items]);

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <IconContainer
        order={
          selected
            ? ['ascend', 'descend'].includes(selected as SortOrder)
              ? (selected as SortOrder)
              : 'all'
            : undefined
        }
        filtered={selected ? true : false}
        style={{ marginLeft: '0.4rem' }}
      >
        <div className="wrapper">
          <CaretUpOutlined />
          <CaretDownOutlined />
        </div>
      </IconContainer>
    </Dropdown>
  );
};
