import { Column, ColumnConfig } from '@ant-design/plots';
import React from 'react';
import { InfluencerPerDayResponsePayload } from 'shared/interfaces/IInfluencerPerDay';

type Props = {
  influencerPerDayData: InfluencerPerDayResponsePayload | undefined;
  width: number;
  height: number;
};

const BarChart: React.FC<Props> = ({ influencerPerDayData, width, height }: Props) => {
  const data = influencerPerDayData
    ? influencerPerDayData?.data?.map((data) => {
        const date = data.date.substring(0, 5);
        const value = data.count;
        return { date: date, value: value };
      })
    : [];

  //console.log(data);

  // const data = [
  //   { date: '5/18', value: 300 },
  //   { date: '5/19', value: 500 },
  //   { date: '5/20', value: 400 },
  //   { date: '5/21', value: 550 },
  //   { date: '5/22', value: 575 },
  //   { date: '5/23', value: 200 },
  //   { date: '5/24', value: 150 },
  // ];

  const config: ColumnConfig = {
    data,
    width: width,
    height: height,
    columnWidthRatio: 0.5,
    xField: 'date',
    yField: 'value',
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      date: {
        alias: 'Date',
      },
      value: {
        alias: 'Influencers',
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-left',
    },
  };
  return <Column {...config} />;
};

export default BarChart;
