import { useQuery } from 'react-query';
import { CountriesDao } from 'shared/dao/CountriesDao';
import { CountriesRequestPayload, CountriesResponsePayload } from 'shared/interfaces/ICountries';

export const useCountryService = () => {
  const { getCountryData: getCountryDataDao } = CountriesDao();
  const getCountry = (payload?: CountriesRequestPayload) => {
    const { data, error, isLoading, isError } = useQuery<CountriesResponsePayload>(['countries', payload], () =>
      getCountryDataDao(payload),
    );
    return {
      data,
      error,
      isLoading,
      isError,
    };
  };
  return {
    getCountry,
  };
};
