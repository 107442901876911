import styled from 'styled-components';

interface IconContainerProps {
  order?: 'ascend' | 'descend' | 'all';
  filtered?: boolean;
}

export const SorterDropdownContainer = styled.div`
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  border-radius: 2px;
`;

export const SorterDropdownItem = styled.div`
  cursor: pointer;
  padding: 0.375rem;
  border-radius: 2px;
  transition: all 0.2s;

  &:hover &:focus {
    background: #f5f5f5;
  }

  &[aria-selected='true'] {
    background: #f0f0f0;
  }
`;

export const IconContainer = styled.div<IconContainerProps>`
  display: grid;
  place-content: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    aspect-ratio: 1/1;
    width: 24px;
    cursor: pointer;
    transition: all 0.3s;

    span.anticon {
      transition: all 0.3s;
      &-caret-down {
        color: ${({ order }) => (order === 'descend' || order === 'all' ? '#1890FF' : '#bfbfbf')};
      }
      &-caret-up {
        color: ${({ order }) => (order === 'ascend' || order === 'all' ? '#1890FF' : '#bfbfbf')};
      }
    }
  }

  &:hover {
    background: #f1f1f1;

    span.anticon {
      &-caret-down {
        color: ${({ order }) => (order === 'descend' || order === 'all' ? '#1890FF' : '#919191')};
      }
      &-caret-up {
        color: ${({ order }) => (order === 'ascend' || order === 'all' ? '#1890FF' : '#919191')};
      }
    }
  }
`;
