import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { transformPayload } from 'shared/utils/transformPayload';
import { InfluencerPerDayRequestPayload, InfluencerPerDayResponsePayload } from 'shared/interfaces/IInfluencerPerDay';

const { GET } = useAxios();

export const InfluencerPerDayDao = () => {
  const getInfluencerPerDayData = async (payload?: InfluencerPerDayRequestPayload) => {
    const transformedPayload = transformPayload<InfluencerPerDayRequestPayload>(payload);
    const response = await GET<InfluencerPerDayResponsePayload>({
      url: `${ENDPOINTS.INFLUENCER_PER_DAY}`,
      params: {
        token: transformedPayload,
      },
    });
    return response.data;
  };
  return {
    getInfluencerPerDayData,
  };
};
