import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SIDEBAR_MENU_LIST } from 'shared/constants/SIDEBAR_MENU_LIST';

import { Menu } from './Sidebar.styled';

const SidebarMenu: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const getActiveMenu = useMemo(() => {
    const parentRoute = pathname.split('/')[1];
    return parentRoute;
  }, [pathname]);
  const getSelectedKey = useMemo(() => {
    const [, parent, child] = pathname.split('/');
    return `/${parent}/${child}`;
  }, [pathname]);

  return (
    <Menu defaultSelectedKeys={[getSelectedKey]} defaultOpenKeys={[getActiveMenu]} mode="inline" theme="dark">
      {SIDEBAR_MENU_LIST.map(({ Icon, ...item }) => (
        <Menu.Item key={item.to?.toLowerCase() || ''} icon={Icon} onClick={() => history.push(item.to || '#')}>
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default React.memo(SidebarMenu);
