import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { useCustomTopicService } from 'shared/services/CustomTopicService';
import CustomTopicListView from './CustomTopicListView';
import { Modal } from 'antd';
import { useQueryClient } from 'react-query';
import { Alert } from 'shared/theme/elements';
import { ROUTES } from 'shared/constants/ROUTES';
import { CUSTOMTOPICSORT } from 'shared/constants/CUSTOM_TOPIC_SORT';
import { useCountryService } from 'shared/services/CountryService';
import { DefaultOptionType } from 'antd/lib/select';
import { useSearch } from 'shared/hooks/useSearch';
// import { useFilter } from 'shared/hooks/useFilter';
import { useSort } from 'shared/hooks/useSort';
import { useFormik } from 'formik';

const { confirm } = Modal;

export interface CustomTopicsFilter {
  website_url?: string[];
  sort?: string;
  country?: string[];
  subtopics?: string[];
  keywords?: string[];
  industry?: string[];
}

export interface CustomTopicSort {
  sort_by?: CUSTOMTOPICSORT;
}

// For searching filters for Custom Topics
export interface CustomTopicsFilterSearch {
  website_url?: string[];
  keywords?: string[];
  country_name?: string | any;
}

// Item for Select Options
export interface FilterSelectOptionsItem {
  data?: DefaultOptionType[];
  loading: boolean;
}

// Select Options for Filtering
export interface FilterSelectOptions {
  country?: FilterSelectOptionsItem;
  website_url?: FilterSelectOptionsItem;
  //
  industry?: FilterSelectOptionsItem;
  subtopics?: FilterSelectOptionsItem;
}

// const filterDefaultValues = {
//   industry: undefined,
//   subtopics: undefined,
// };

const searchDefaultValues = {
  keywords: undefined,
};

const sortDefaultValues = {
  sort_by: undefined,
};

const CustomTopicListContainer = () => {
  const { push } = useHistory();

  const queryClient = useQueryClient();

  // Pagination
  const pagination = usePagination();
  const { page, pageSize, handlePageChange } = pagination;

  // Filters
  // const { filter, setFilter } = useFilter<CustomTopicsFilter>(filterDefaultValues);

  const [filter, setFilter] = useState<CustomTopicsFilter>({
    sort: undefined,
    country: undefined,
    subtopics: undefined,
    keywords: undefined,
    industry: undefined,
  });

  const handleOnFilter = (key: keyof CustomTopicsFilter, value: any) => {
    setFilter((current) => ({ ...current, [key]: value }));
    handlePageChange(1);
  };

  const addtionalFilterForm = useFormik({
    initialValues: {
      keywords: undefined,
    },
    onSubmit: (values) => {
      setFilter((curr) => ({ ...curr, ...values }));
    },
  });
  //For Filtering

  // Sort
  const { sort, setSort } = useSort<CustomTopicSort>(sortDefaultValues);
  const handleOnSort = (key: keyof CustomTopicSort, value: any) => {
    setSort(key, value);
    handlePageChange(1);
  };

  // Search
  const { search, setSearch } = useSearch<CustomTopicsFilterSearch>(searchDefaultValues);

  const handleOnSearch = (key: keyof CustomTopicsFilterSearch, value: any) => {
    setSearch(key, value);
    handlePageChange(1);
  };

  // Custom Topics Service GET ALL DATA
  const { getCustomTopics, deleteCustomTopic } = useCustomTopicService();
  const { deleteMutation } = deleteCustomTopic();
  const { data: customTopicsData, isLoading: customTopicsIsLoading } = getCustomTopics({
    page,
    limit: pageSize,
    sort: sort.sort_by,
    website_url: filter.website_url || undefined,
    industry: filter.industry || undefined,
    subtopics: filter.subtopics || undefined,
    country: filter.country || undefined,
    keywords: filter.keywords || undefined,
  });

  // <== Country Service
  const { getCountry } = useCountryService();
  const { data: countriesData, isLoading: isCountryLoading } = getCountry({
    page: 1,
    limit: 20,
    country_name: search.country_name,
  });

  // Country Options
  const countriesOptions = useMemo(() => {
    const results = countriesData?.data ? countriesData.data : [];
    return results.map((country) => {
      return {
        value: country.country_name,
        label: country.country_name,
      };
    });
  }, [countriesData]) as DefaultOptionType[];

  // LOGIC TO FILTER REMOVE DUPLICATE DATA AND DISPLAY AS FILTER

  const industryOptions = useMemo(() => {
    const results = customTopicsData?.data ? customTopicsData?.data : [];
    return results
      ?.map(({ industry }) => industry)
      .filter((e: any, i: any, a: string | any[]) => i === a.indexOf(e))
      .map((item: any) => ({ label: item, value: item }));
  }, [customTopicsData]) as DefaultOptionType[];

  const subTopicsOptions = useMemo(() => {
    const results = customTopicsData?.data ? customTopicsData?.data : [];
    return results
      ?.map(({ subtopics }) => subtopics)
      .filter((e: any, i: any, a: string | any[]) => i === a.indexOf(e))
      .map((item: any) => ({ label: item, value: item }));
  }, [customTopicsData]) as DefaultOptionType[];

  // FILTER OPTIONS
  const selectOptions: FilterSelectOptions = useMemo(
    () => ({
      country: {
        data: countriesOptions,
        loading: isCountryLoading,
      },
      industry: {
        data: industryOptions,
        loading: false,
      },
      subtopics: {
        data: subTopicsOptions,
        loading: false,
      },
    }),
    [countriesOptions, isCountryLoading, industryOptions, subTopicsOptions],
  );

  // HANDLE Custom Topics SEARCH
  const handleOnCustomTopicSearch = (value: string) => {
    handleOnFilter('keywords', value);
  };

  // HANDLE EDIT CUSTOM TOPIC

  const handleOnEdit = (id: string) => {
    push(ROUTES.CUSTOMTOPICS.OVERVIEW.EDIT, {
      id,
      filter,
      page,
    });
  };

  // HANDLE DELETE CUSTOM TOPIC
  const customTopicDelete = (id: string) => {
    return new Promise((resolve, reject) => {
      deleteMutation.mutateAsync(id, {
        onSuccess: () => {
          resolve(true);
          Alert.success('Custom Topic Garden Deleted!');
          queryClient.invalidateQueries('custom-topics');
        },
        onSettled: () => {
          (async () => await queryClient.refetchQueries('custom-topics'))();
        },
        onError: (error: any) => {
          reject();
          Alert.error(error.response.data.server_response);
        },
      });
    });
  };
  const handleOnDelete = (id: string) => {
    confirm({
      centered: true,
      title: 'Delete Custom Topic',
      content: 'Are you sure you want to delete this Custom Topic?',
      onOk: async () => await customTopicDelete(id),
      okButtonProps: {
        danger: true,
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      cancelButtonProps: {
        size: 'large',
        style: { borderRadius: '4px', fontSize: '14px' },
      },
      okText: 'Delete',
    });
  };

  return (
    <CustomTopicListView
      data={customTopicsData?.data || []}
      pagination={pagination}
      isDataLoading={customTopicsIsLoading}
      pageTotal={customTopicsData?.meta?.pagination.total || 0}
      //
      onDelete={handleOnDelete}
      onEdit={handleOnEdit}
      //
      sort={sort}
      onSort={handleOnSort}
      //
      onSearch={handleOnSearch}
      onCustomTopicSearch={handleOnCustomTopicSearch}
      search={search}
      //
      options={selectOptions}
      //
      filter={filter}
      onFilter={handleOnFilter}
      //
      addtionalFilterForm={addtionalFilterForm}
    />
  );
};

export default CustomTopicListContainer;
